
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table, Transfer } from 'antd';
import ModalGeral from '../../../components/Modal';
import { CreateDigital, CreateSensor, DeleteSensor, EditSensor, GetSensor } from './ApiSensor';
import { BsDoorOpen, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import { FaFingerprint } from 'react-icons/fa';
import EditarSensor from './EditarSensor';
import AdicionarSensor from './AdicionarSensor';

import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { InputRef } from 'antd';
import Usuarios from './Usuarios';
import { GetUser } from '../../Acesso/Usuario/apiUser';
import { GetGroup } from '../../Acesso/Grupos/apiGrupo';
import Grupos from './Grupos';
import { OpenDoor } from '../../Portas/ApiSensor';
import { Token } from '../../../helpers/token';
import { GetOneUser } from '../UsuarioAdmin/apiUser';
import { CallNotification } from '../../../components/Alert';
import Digital from './Digital';
import DigitalView from './Digital View';


export default function PortasAdmin() {
    interface DataType {
        key: string,
        name: string,
        id: number,
        local: string,
        usuarios: string,
        mac: string,
        group: string,
        digitalUsers: { id: string; user: any }[];

    }

    type DataIndex = keyof DataType;
    const [sensor, setSensor] = useState<any>(null);
    const [idSensor, setIdSensor] = useState<any>(null);
    const [name, setName] = useState('');
    const [mac, setMac] = useState('');
    const [branchId, setBranchId] = useState<any>(null);
    const [usuarios, setUsuarios] = useState<any>(null);
    const [group, setGroup] = useState<any>(null);
    const [passwordSensor, setPasswordSensor] = useState('');
    const [local, setLocal] = useState('');
    const [cardId, setCardId] = useState<any>({});
    const [userId, setUserId] = useState<any>([]);

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [targetKeysGrupos, setTargetKeysGrupos] = useState<string[]>([]);
    const [digView, setDigView] = useState<any>([]);
    const [macDigital, setMacDigital] = useState<any>([]);
    const [idOfList, setIdOfList] = useState<number>(0);



    const IdUser = Token.getTokenData().id

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [openClose, setOpenClose] = useState<boolean>(false);
    const [options, setOptions] = useState<string>('');
    const [modalTitle, setModalTitle] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const modalChildren: { [key: string]: JSX.Element } = {
        adicionar: <AdicionarSensor
            name={name}
            local={local}
            mac={mac}
            branchId={branchId}

            setName={setName}
            setLocal={setLocal}
            setMac={setMac}
            setBranchId={setBranchId}
        />,
        editar: <EditarSensor
            name={name}
            local={local}
            mac={mac}
            branchId={branchId}

            setName={setName}
            setLocal={setLocal}
            setMac={setMac}
            setBranchId={setBranchId}
            idSensor={idSensor}

        />,
        // usuarios: <Usuarios
        //     targetKeys={targetKeys}
        //     setTargetKeys={setTargetKeys}
        //     idSensor={idSensor}
        // />,
        group: <Grupos
            targetKeysGrupos={targetKeysGrupos}
            setTargetKeysGrupos={setTargetKeysGrupos}
            idSensor={idSensor}
        />,
        digital: <Digital
            userId={userId}
            setUserId={setUserId}

        />,
        digitalView: <DigitalView
            idSensor={idSensor}


        />
    }

    const getData = () => {
        GetSensor(setSensor)
        GetUser(setUsuarios)
        GetGroup(setGroup)
        GetOneUser(IdUser, setCardId)
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined rev={1} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined rev={2} style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name'),

        },

        {
            title: 'Local',
            dataIndex: 'local',
            key: 'local',
            width: '20%',
            ...getColumnSearchProps('local'),
        },
        {
            title: 'Digital',
            dataIndex: 'digitalUsers',
            key: 'digitalUsers',
            width: '20%',
            ...getColumnSearchProps('digitalUsers'),
            render: (_, record) => (

                <Space size="large">
                    {/* {console.log(record)} */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px'

                    }}>


                        <Space size="large">
                            <a onClick={() => {
                                setIdSensor(record.id)
                                setOptions('digitalView');
                                setOpenClose(!openClose);
                                setModalTitle('Usuários cadastrados')
                            }}>{<FiEye />}</a>
                        </Space>
                    </div>

                </Space>)
        },
        {
            title: 'Grupos',
            dataIndex: 'group',
            key: 'group',
            width: '20%',
            ...getColumnSearchProps('group'),
            render: (_, record) => (

                <Space size="large">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px'

                    }}>

                        <Space size="large">
                            <a onClick={() => {
                                setIdSensor(record.id)
                                setOptions('group');
                                setOpenClose(!openClose);
                                setModalTitle('Editar grupo')
                            }}>{<BsPencilSquare />}</a>
                        </Space>
                    </div>

                </Space>
            ),
        },
        {
            title: 'Mac',
            dataIndex: 'mac',
            key: 'mac',
            width: '20%',
            ...getColumnSearchProps('mac'),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '3%',
            // ...getColumnSearchProps('action'),
            render: (_, record) => {
                const lastNumber =
                    record.digitalUsers.length > 0
                        ? record.digitalUsers[record.digitalUsers.length - 1].id
                        : 0;


                // console.log(record)

                return (
                    <Space size="large" >
                        <a onClick={() => {
                            // @ts-ignore
                            setIdOfList(lastNumber + 1);
                            setMacDigital(record.mac);
                            setIdSensor(record.id);
                            setOptions('digital');
                            setOpenClose(!openClose);
                            setModalTitle('Cadastrar digital')
                        }}>{<FaFingerprint />}</a>

                        <a onClick={() => {
                            setIdSensor(record.id)
                            setOptions('editar');
                            setOpenClose(!openClose);
                            setModalTitle('Editar sensor')
                        }}>{<BsPencilSquare />}</a>
                        <a
                            onClick={() => {
                                setIdSensor(record.id)
                                setOptions('deletar');
                                setOpenClose(!openClose);
                                setModalTitle('Tem certeza que deseja deletar este sensor?')
                                setName(record.name)
                            }}
                        >{<BsTrash />}</a>


                        <Button onClick={() => {
                            OpenDoor(record.mac, parseInt(cardId.cardId), () => { CallNotification("Sucesso", "Porta aberta com sucesso!", "success"); }, () => {
                                CallNotification("Error", "Usuário sem permissão para abrir esta porta!", "danger");
                            });



                            // setOptions('editar');
                            // setOpenClose(!openClose);
                            // setModalTitle('Editar sensor')
                        }}>abrir</Button>


                    </Space >)
            },

        },
    ];

    // console.log(userId)




    const handleCreate = () => {
        const create = {
            name: name,
            local: local,
            mac: mac,
            branchId: branchId
        }
        CreateSensor(create,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }


    const handleDigital = () => {



        CreateDigital(
            idOfList,
            macDigital,
            userId
        )
    }




    const handleEdit = () => {
        const edit: any = {
            name: name,
            local: local,
            mac: mac,
            branchId: branchId
        }
        EditSensor(
            idSensor,
            edit,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }

    const handleEditUser = () => {
        let GruposSelecionados: any = []
        group?.map((value: any) => {
            if (targetKeysGrupos.includes(value.id.toString())) {
                GruposSelecionados.push({
                    id: value.id,
                })
            }
        })
        const edit: any =
        {
            "group": {
                "set": GruposSelecionados
            }
        }

        EditSensor(
            idSensor,
            edit,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }

    const handleDelete = () => {
        DeleteSensor(idSensor, () => {
            getData()
        }, () => {
            getData()
        }
        )
    }



    const data: DataType[] = sensor?.map((value: any, index: any) => {

        return {
            key: index + 1,
            name: value.name,
            id: value.id,
            local: value.local,
            usuarios: value.usuarios,
            group: value.group,
            mac: value.mac,
            digitalUsers: value.digitalUsers,

        }
    })



    // ==================================================xxxxxxxxxxxxxxxxx==============================================================

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Button
                    type="primary" style={{ margin: '10px' }}
                    onClick={() => {
                        setOpenClose(!openClose);
                        setModalTitle('Adicionar sensor');
                        setOptions('adicionar');
                    }}
                >Adicionar sensor</Button>
            </div>

            <Table columns={columns} dataSource={data} />

            <ModalGeral

                title={modalTitle}

                confirm={() => {
                    if (options == 'adicionar') { return handleCreate(), setOpenClose(!openClose) }
                    if (options == 'digital') { return handleDigital(), setOpenClose(!openClose) }
                    if (options == 'editar') { return handleEdit(), setOpenClose(!openClose) }
                    if (options == 'group') { return handleEditUser(), setOpenClose(!openClose) }
                    if (options == 'deletar') { return handleDelete(), setOpenClose(!openClose) }
                }}
                cancel={() => {
                    setOpenClose(!openClose);
                    getData();
                    if (options == 'adicionar') {
                        setName('');
                        setMac('');
                        setLocal('');
                        setBranchId([]);
                    }
                }}
                isModalOpen={openClose}
                children={options == 'deletar' ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                        <Button value="large">{name}</Button>
                    </div>
                    : modalChildren[options]}
            />
        </div>
    )
}