import styled from "styled-components";

export const Colors = styled.div`
  width: 100%;
  height: 100%;
  --color1: ${(props) => props.theme.colors.primary};
  --color2: ${(props) => props.theme.colors.secondary};
  --color3: ${(props) => props.theme.colors.gray};
  --color4: ${(props) => props.theme.colors.success};
  --color5: ${(props) => props.theme.colors.header};
  --color6: ${(props) => props.theme.colors.info};  
  --text-color: ${(props) => props.theme.colors.textColor};
  --gray-0: ${(props) => props.theme.colors.gray0};
  --gray-100: ${(props) => props.theme.colors.gray100};
  --gray-200: ${(props) => props.theme.colors.gray200};
  --gray-300: ${(props) => props.theme.colors.gray300};
  --gray-400: ${(props) => props.theme.colors.gray400};
  --gray-500: ${(props) => props.theme.colors.gray500};
  --gray-600: ${(props) => props.theme.colors.gray600};
  --gray-700: ${(props) => props.theme.colors.gray700};
  --gray-800: ${(props) => props.theme.colors.gray800};
  --gray-900: ${(props) => props.theme.colors.gray900};
  --gray-1000: ${(props) => props.theme.colors.gray1000};
  --blue-200: ${(props) => props.theme.colors.blue200};
  --blue-300: ${(props) => props.theme.colors.blue300};
  --blue-400: ${(props) => props.theme.colors.blue400};
  --blue-500: ${(props) => props.theme.colors.blue500};
  --blue-700: ${(props) => props.theme.colors.blue700};
  --red-300: ${(props) => props.theme.colors.red300};
  --red-500: ${(props) => props.theme.colors.red500};
  --red-700: ${(props) => props.theme.colors.red700};
  --green-200: ${(props) => props.theme.colors.green200};
  --green-500: ${(props) => props.theme.colors.green500};
  --green-700: ${(props) => props.theme.colors.green700};
  --yellow-200: ${(props) => props.theme.colors.yellow200};
  --yellow-500: ${(props) => props.theme.colors.yellow500};
  --yellow-700: ${(props) => props.theme.colors.yellow700};
  --static-gray-0: ${(props) => props.theme.colors.static_gray0};
  --static-gray-100: ${(props) => props.theme.colors.static_gray100};
  --static-gray-200: ${(props) => props.theme.colors.static_gray200};
  --static-gray-300: ${(props) => props.theme.colors.static_gray300};
  --static-gray-400: ${(props) => props.theme.colors.static_gray400};
  --static-gray-500: ${(props) => props.theme.colors.static_gray500};
  --static-gray-600: ${(props) => props.theme.colors.static_gray600};
  --static-gray-700: ${(props) => props.theme.colors.static_gray700};
  --static-gray-800: ${(props) => props.theme.colors.static_gray800};
  --static-gray-900: ${(props) => props.theme.colors.static_gray900};
  --static-gray-1000: ${(props) => props.theme.colors.static_gray1000};
  --static-blue-200: ${(props) => props.theme.colors.static_blue200};
  --static-blue-300: ${(props) => props.theme.colors.static_blue300};
  --static-blue-400: ${(props) => props.theme.colors.static_blue400};
  --static-blue-500: ${(props) => props.theme.colors.static_blue500};
  --static-blue-700: ${(props) => props.theme.colors.static_blue700};
  --static-red-300: ${(props) => props.theme.colors.static_red300};
  --static-red-500: ${(props) => props.theme.colors.static_red500};
  --static-red-700: ${(props) => props.theme.colors.static_red700};
  --static-green-200: ${(props) => props.theme.colors.static_green200};
  --static-green-300: ${(props) => props.theme.colors.static_green300};
  --static-green-500: ${(props) => props.theme.colors.static_green500};
  --static-green-700: ${(props) => props.theme.colors.static_green700};
  --static-yellow-200: ${(props) => props.theme.colors.static_yellow200};
  --static-yellow-500: ${(props) => props.theme.colors.static_yellow500};
  --static-yellow-700: ${(props) => props.theme.colors.static_yellow700};
  --static-yellow-800: ${(props) => props.theme.colors.static_yellow800};
`;
