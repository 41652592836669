import { ButtonLogin, Div1, H1, Label } from "./styles";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../router/ProvideAuth";
import { BsDoorOpen } from "react-icons/bs";
import styled from "styled-components";
import {
  blue,
  gray,
  cyan,
  presetDarkPalettes,
  presetPrimaryColors,
} from "@ant-design/colors";

import React from "react";
import { Button, Checkbox, Form, Input, App, message, theme } from "antd";
import { WindowWidth } from "../../components/WidthXHeigth";

// console.log("presetDarkPalettes", presetDarkPalettes);
// console.log("presetPrimaryColors", presetPrimaryColors);

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const Login: React.FC = () => {
  let history = useHistory();
  const auth: any = useAuth() || {};
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const [messageApi, contextHolder] = message.useMessage();

  // notification.info({ message: 'Good' });
  // modal.warning({ title: 'Good' });

  const onFinish = (values: any) => {
    const { password, username } = values;
    // console.log("Success:", values);

    const data = {
      email: username,
      password: password,
    };
    // @ts-ignore
    auth.signin(() => {
      history.push("/dashboards");
    }, data);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    const { errorFields } = errorInfo;
    if (errorFields[0].errors[0])
      messageApi.open({
        type: "error",
        content: errorFields[0].errors[0],
      });
  };



  return (
    <Container background={colorPrimary}>
      {contextHolder}
      <section
        style={{
          background: colorBgContainer,
          border: `1px solid ${cyan.at(1)}`,
          // width: WindowWidth >= 500 ? '50%' : '94%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* <button onClick={() => {alert(`${WindowWidth}`)}}>ver</button> */}

        <div style={{ color: 'green', fontSize: '22px', margin: '0px 0px 20px 0px' }} >Controle de acesso</div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: WindowWidth >= 500 ? '600px' : '94%', }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            style={{ width: WindowWidth >= 500 ? '600px' : '100%', }}
            label="Email"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            style={{ width: WindowWidth >= 500 ? '600px' : '100%', }}
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item<FieldType>
            style={{ width: WindowWidth >= 500 ? '600px' : '100%', }}
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </section>
    </Container>
  );
};

export default Login;

const Container = styled.div<{ background?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.background};

  section {
    border-radius: 15px;
  
    /* height: 100%; */

    padding: 2em;
    align-items: center;
  }
`;

