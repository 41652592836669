export const parseJwt = (token: string) => {
  if (!token) return "";
  var base64Url = token.split(".")[1] || "";
  var base64 = base64Url?.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const formNameImage = (name: string): string => {
  const nameArray = name.split(" ");
  if (nameArray.length > 1)
    return `${nameArray[0].charAt(0).toUpperCase()} ${nameArray[1]
      .charAt(0)
      .toUpperCase()}`;
  if (nameArray.length > 0) return `${nameArray[0].charAt(0).toUpperCase()}`;
  else return "";
};

export const getBase64FromImage = (
  file: any,
  onLoad: { (fileString: any): void; (arg0: string | ArrayBuffer | null): void }
) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    onLoad(reader.result);
  };
};
