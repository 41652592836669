import axios from "axios";
import { ACCESS, DELETE_DIGITAL, OPENDOOR, SENSOR } from "../../constains";
import { Dispatch, SetStateAction } from "react";
import { CallNotification } from "../../components/Alert";
const token = window.localStorage.getItem("accessToken");
const access = {
    headers: {
        Authorization: "Bearer " + token,
    },
};

export const CreateSensor = (
    Sensor: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .post(`${SENSOR}`, Sensor, access)
        .then(response => {
            sucessCallback()
            CallNotification("Sucesso", "Sensor cadastrado com sucesso!", "success");
        })
        .catch(error => {
            failureCallback()
            CallNotification("Error", "Falha ao cadastrar sensor!", "danger");
        });

}
export const GetSensor = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${SENSOR}`, access)
        .then(res => {
            // console.log(res.data.data)
            setState(res.data.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}

export const OpenDoor = (
    mac: any,
    cardId: any,
    // setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        // .get(`${ACCESS}?mac=${mac}&cardId=${cardId}`, access)
        .get(`${ACCESS}?mac=${mac}&cardId=${cardId}`, access)
        .then(res => {
            // console.log(res.data.access)
            // console.log(mac)

            { res.data.access === true ? axios.get(`${OPENDOOR + mac}`, access) : console.log('Não abriu') }

            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}

export const GetOneSensor = (
    id: string,
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${SENSOR}/${id}`, access)
        .then(res => {
            // console.log(res.data.sensor)
            setState(res.data.sensor);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const EditSensor = (
    id: string,
    sensor: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .put(`${SENSOR}/${id}`, sensor, access)
        .then(res => {
            // console.log(sensor)
            CallNotification("Sucesso", "Sensor editado com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao editar sensor!", "danger");
            failureCallback()
        });

}
export const DeleteSensor = (
    id: string,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .delete(`${SENSOR}/${id}`, access)
        .then(res => {

            CallNotification("Sucesso", "Sensor deletado com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao deletar sensor!", "danger");
            failureCallback()
        });

}
export const DeleteDigital = (
    mac: string,
    idDigital: string,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .delete(`${DELETE_DIGITAL}?mac=${mac}&id=${idDigital}`, access) 
        .then(res => {

            CallNotification("Sucesso", "Digital deletada com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao deletar digital!", "danger");
            failureCallback()
        });

}

