//@ts-nocheck

import React, { useContext, createContext, useState, Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Login from "../views/Login/Login";
import { CallNotification } from "../components/Alert/index";
import { LOGIN_URL } from "../constains";

export default function AuthExample() {
  return (
    <ProvideAuth>
      <Router>
        <div>
          <ul>
            <li>
              <Link to="/public">Public Page</Link>
            </li>
            <li>
              <Link to="/protected">Protected Page</Link>
            </li>
          </ul>

          <Switch>
            <Route exact path="/public">
              <PublicPage />
            </Route>
            <Route exact path="/login">
              <LoginPage />
            </Route>

            <Route exact path="/">
              <Login />
            </Route>

            <PrivateRoute path="/protected">
              <ProtectedPage />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
// @ts-ignore
const authContext = createContext();

export function ProvideAuth({ children }: { children: any }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  interface User {
    id?: string;
    name?: string;
    email?: string;
    password?: string;
    accessToken?: string;
  }

  const [user, setUser] = useState<User>(null);

  const signin = (
    cb: () => void,
    user: { email: string; password: string }
  ) => {
    fetch(LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        response?.json()?.then((res) => {
          const { status = false, data = null } = res || {};
          if (status) {
            (() => {
              setUser(data);
              const { accessToken = "" } = data;
              localStorage.setItem("accessToken", accessToken);
              cb();
            })();
          } else
            CallNotification("Erro", "Senha ou Usuário incorreto", "danger");
        });
      })
      .catch((e) => {
        console.error("deu er", e);
        CallNotification("Erro", "Senha ou Usuário incorreto", "danger");
      });
  };

  const signout = (cb: () => void) => {
    (() => {
      setUser(null);
      localStorage.removeItem("accessToken");
      cb();
    })();
  };

  return {
    user,
    signin,
    signout,
  };
}

function AuthButton() {
  let history = useHistory();
  let auth = useAuth();

  const accessToken = auth?.user?.accessToken || null;
  let component = <></>;

  if (localStorage.getItem("accessToken") != null || accessToken != null) {
    component = (
      <p>
        Welcome!{" "}
        <button
          onClick={() => {
            auth.signout(() => history.push("/"));
          }}
        >
          Sign out
        </button>
      </p>
    );
  } else {
    component = <p>You are not logged in.</p>;
  }

  return component;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }: any) {
  let auth = useAuth();
  // console.log("auth Private", auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("accessToken") ? (
          // auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  let login = () => {
    auth.signin(
      () => {
        history.replace(from);
      },
      {
        name: "test2",
        email: "test2@test2.com",
        password: "test",
      }
    );
  };

  return (
    <div>
      <p>You must log in to view the page at {from.pathname}</p>
      <button onClick={login}>Log in</button>
    </div>
  );
}
