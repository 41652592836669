import axios from "axios";
import { EMPRESA, USER } from "../../../constains";
import { Dispatch, SetStateAction } from "react";
import { CallNotification } from "../../../components/Alert";
const token = window.localStorage.getItem("accessToken");
const access = {
    headers: {
        Authorization: "Bearer " + token,
    },
};




export const CreateEmpresa= (
    user: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .post(`${EMPRESA}`, user, access)
        .then(response => {
            sucessCallback()
            CallNotification("Sucesso", "Empresa cadastrada com sucesso!", "success");
        })
        .catch(error => {
            failureCallback()
            CallNotification("Error", "Falha ao cadastrar empresa!", "danger");
        });

}
export const GetEmpresa = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${EMPRESA}`, access)
        .then(res => {
            // console.log(res.data.data)
            setState(res.data.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const GetOneEmpresa = (
    id: string,
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${EMPRESA}/${id}`, access)
        .then(res => {
            // console.log(res.data.branch)
            setState(res.data.branch);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const EditEmpresa = (
    id: string,
    user: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .put(`${EMPRESA}/${id}`, user, access)
        .then(res => {
            
            CallNotification("Sucesso", "Empresa editada com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao editar empresa!", "danger");
            failureCallback()
        });

}
export const DeleteEmpresa = (
    id: string,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .delete(`${EMPRESA}/${id}`, access)
        .then(res => {
     
            CallNotification("Sucesso", "Empresa deletada com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao deletar empresa!", "danger");
            failureCallback()
        });

}

