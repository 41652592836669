import React from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Breadcrumb, Button, Layout, Menu, theme } from "antd";
import { useHistory } from "react-router-dom";
import { BsGearWideConnected, BsDoorOpen } from "react-icons/bs";
import { AiOutlineImport, AiOutlineHistory } from "react-icons/ai";
import { Token } from "../../../helpers/token";
import { ScrollBar } from '../index'

const { Header, Content, Footer, Sider } = Layout;

function Aside({ children }: any) {
  const padrao: any = window.localStorage.getItem("menu") || "1";
  const [collapsed, setCollapsed] = React.useState(true);
  const history = useHistory();
  const [navigation, setNavigation] = React.useState<any>([
    { nav: "Empresa" },
  ]);

  const items: MenuProps["items"] = [
    {
      key: 1,
      icon: React.createElement(BarChartOutlined),
      label: `Empresa`,
      // children: [{
      //   key: '2',
      //   label: 'Home',
      // }],
      onClick: () => {
        history.push("/admin");
        setNavigation([{ nav: "Empresa" }]);
        window.localStorage.setItem("menu", "1");
      },
    },
    {
      key: 2,
      icon: React.createElement(AiOutlineImport),
      label: `Usuários`,
      // children: [{
      //   key: '4',
      //   label: 'Cadastro',
      // }],
      onClick: () => {
        history.push("/usuariosadmin");
        setNavigation([{ nav: "Usuários" }]);
        window.localStorage.setItem("menu", "2");
      },
    },
    {
      key: 3,
      icon: React.createElement(BsDoorOpen),
      label: `Grupos`,
      // children: [{
      //   key: '4',
      //   label: 'Cadastro',
      // }],
      onClick: () => {
        history.push("/gruposadmin");
        setNavigation([{ nav: "Grupos" }]);
        window.localStorage.setItem("menu", "3");
      },
    },
    {
      key: 4,
      icon: React.createElement(BsDoorOpen),
      label: `Portas`,
      // children: [{
      //   key: '4',
      //   label: 'Cadastro',
      // }],
      onClick: () => {
        history.push("/portasadmin");
        setNavigation([{ nav: "Portas" }]);
        window.localStorage.setItem("menu", "4");
      },
    },
    {
      key: 5,
      icon: React.createElement(AiOutlineHistory),
      label: `Histórico`,
      // children: [{
      //   key: '4',
      //   label: 'Cadastro',
      // }],
      onClick: () => {
        history.push("/historicoadmin");
        setNavigation([{ nav: "Histórico" }]);
        window.localStorage.setItem("menu", "5");
      },
    },
    {
      key: 6,
      icon: React.createElement(AiOutlineHistory),
      label: `Lista de MACs`,
      // children: [{
      //   key: '4',
      //   label: 'Cadastro',
      // }],
      onClick: () => {
        history.push("/listofmac");
        setNavigation([{ nav: "Lista de mac" }]);
        window.localStorage.setItem("menu", "6");
      },
    },
    // {
    //   key: 6,
    //   icon: React.createElement(BsGearWideConnected),
    //   label: `Configurações`,
    //   // children: [{
    //   //   key: '6',
    //   //   label: 'Usuário',
    //   // }],
    //   onClick: () => {
    //     history.push("/configuracao");
    //     setNavigation([{ nav: "Configurações" }]);
    //   },
    // },

  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // const { admin } = Token.getTokenData();

  return (
    <ScrollBar style={{ height: "100%" }}>
      <Layout style={{ height: "100vh" }}>
        {/* <Header style={{ display: 'flex', alignItems: 'center' }}>
          Nome da empresa
        </Header> */}

        <Content style={{ padding: "0 50px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Breadcrumb style={{ margin: "16px 0" }}
              items=
              {navigation !== null ? (
                navigation?.map(({ nav }: any) => {

                  return { title: nav }

                })
              ) : (
                <></>
              )}


            />




        

            <div style={{ display: "flex", gap: "20px" }}>
              <Button
                onClick={() => {
                  history.push("/dashboards");
                }}
              >
                dashboards
              </Button>

              <Button
                onClick={() => {
                  history.push("/");
                  window.localStorage.setItem("menu", "1");
                }}
              >
                Sair
              </Button>
            </div>
          </div>

          <Layout
            style={{
              padding: "24px 0",
              background: colorBgContainer,
              height: "100%",
            }}
          >
            <Sider
             collapsible
             collapsed={collapsed}
             onCollapse={(value) => setCollapsed(value)}
              style={{ background: colorBgContainer, height: "90%" }}
              width={250}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={[padrao]}
                defaultOpenKeys={[padrao]}
                style={{ height: "100%" }}
                items={items}
              />
            </Sider>

            <Content
              className='TheScroll'
              style={{
                padding: "0 24px",
                minHeight: 280,
                overflow: 'auto',
                margin: '0px 0px 20px 0px',
              }}>

              {children}


            </Content>

            {/* <Content style={{ padding: "0 24px", minHeight: 280 }}>
              {children}
            </Content> */}
          </Layout>
        </Content>

        <Footer
          style={{ textAlign: "center", margin: "10px", padding: "10px" }}
        >
          {new Date().getFullYear()} made by - Arcanine
        </Footer>
      </Layout>
    </ScrollBar>
  );
}

export default Aside;
