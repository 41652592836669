import React from 'react'
import { GetListMac } from './api'
import { Button } from 'antd'

export default function ListOfMac() {
  const [list, setList] = React.useState<any>([])

  React.useEffect(() => {
    GetListMac(setList)
  }, [])

  // console.log(list)

  return (
    <div style={{
      display: ' flex',
      flexDirection: 'column'
    }}>

      <span style={{ color: ' green', fontSize: ' 17px' }}>Lista de dispositivos conectados
      </span>

      {list?.map((mac: any) => {
        return <Button>{mac}</Button>
      }

      )}

    </div>
  )
}