/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import sidebarBg from "./assets/bg1.jpg";
import { AiTwotoneHome } from "react-icons/ai";
import { MdKeyboardArrowRight, MdOutlineSensors } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { HiUsers, HiBell } from "react-icons/hi";
import { ImNotification } from "react-icons/im";
import { ImPower } from "react-icons/im";
import { FaTemperatureLow } from "react-icons/fa";
import styled from "styled-components";
// import { ADMIN_BRANCH_URL, NOTIFICATION } from "../../constains";
import axios from "axios";
// import { Badge } from "./Badge";
import './styles.scss';
import { Token } from "../../helpers/token";

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar, setNavigation, tela }: any) => {
  const history = useHistory();

  const [collapsedButton, setCollapsedButton] = useState(false);
  const empresa = window.localStorage.getItem("empresa");


  const { admin, name, ...props2 } = Token.getTokenData();

//   useEffect(() => {
//     axios
//       .get(`${ADMIN_BRANCH_URL}/${branch}`, access)
//       .then((res) => {
//         setName(res.data.branch.name);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, []);

//   const getActiveAlerts = () => {
//     axios
//       .get(`${NOTIFICATION}?branchId=${branch}&isActive=true`, access)
//       .then((res) => {
//         setAlertas(res.data.data.length);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       getActiveAlerts();
//     }, 20 * 1000);

//     return () => clearInterval(intervalId);

//   }, [alertas]);


  return (
    <ProSidebar
    //   image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsedButton}
      toggled={toggled}
      breakPoint="lg"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <SideBarTitle
        >
          {empresa}
          
        </SideBarTitle>
      </SidebarHeader>


      <SidebarContent className='SideBarMenu'>
        <Menu 
        // iconShape="undefined"
         className='MenuGeral'>
          <MenuItem 
          onClick={() => {setNavigation('Dashboard')}} 
          className='menuItem' 
          icon={<AiTwotoneHome 
          className='iconMenu'
           />}>
            
            Dashboard <Link to="/dashboard" />
          </MenuItem>

          <SubMenu 
          className='menuItem' 
          title="Acesso" 
          icon={
          <FaTemperatureLow 
            className='iconMenu'
           />
          }
          >

            <MenuItem 
            onClick={() => {setNavigation('Usuários')}} 
            className='menuItem'  
            icon={<MdOutlineSensors 
              className='iconMenu'
            />}>
              Usuários <Link to="/usuarios" />


            </MenuItem>
            <MenuItem 
            onClick={() => {setNavigation('Grupos')}} 
             className='menuItem' 
            // style={menuItem}
            >
              Grupos <Link 
              
              to="/grupos" />{" "}
            </MenuItem>
          </SubMenu>

          {/* <SubMenu 
          className='menuItem'  
          title="Energia" 
          icon={<ImPower 
            className='iconMenu'
           />}>
            <MenuItem 
            className='menuItem' 
            icon={<MdOutlineSensors 
              className='iconMenu'
             />}>
              Sensores <Link to="/energia" />
            </MenuItem>
            <MenuItem 
            className='menuItem' 
            >
              Configuração <Link to="/energia/config" />{" "}
            </MenuItem>

          </SubMenu> */}

          <MenuItem 
          
          className='menuItem' 
          onClick={() => {setNavigation('Portas')}} 
          icon={<ImNotification 
            className='iconMenu'
          />}>
            Portas <Link to="/portas" />{" "}
          </MenuItem>
          <MenuItem 
          
          className='menuItem' 
          onClick={() => {setNavigation('Histórico')}} 
          icon={<ImNotification 
            className='iconMenu'
          />}>
            Hitórico <Link to="/historico" />{" "}
          </MenuItem>
          <MenuItem 
          
          className='menuItem' 
          onClick={() => {setNavigation('Configurações')}} 
          icon={<ImNotification 
            className='iconMenu'
          />}>
            Configurações <Link to="/configuracao" />{" "}
          </MenuItem>
{/* 
          <MenuItem
            className='menuItem' 
            icon={<HiBell 
              className='iconMenu'
               />}
            suffix={
              alertas > 0 ? (
                <Badge variant="danger" shape="circle">
                  {alertas}
                </Badge>
              ) : null
            }
          >
            Avisos <Link to="/warnings" />
          </MenuItem> */}

          <ViewBar>
            <div className="bar">
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={() => setCollapsedButton(!collapsedButton)}
              >
                {collapsedButton === false ? (
                  <MdKeyboardArrowLeft size={40} color="white" />
                ) : (
                  <MdKeyboardArrowRight size={40} color="white" />
                )}
              </button>
            </div>
          </ViewBar>
        </Menu>
      </SidebarContent>

      {/* <SidebarFooter style={{ textAlign: "center" }}>
        <div className="sidebar-btn-wrapper"></div>
      </SidebarFooter> */}


      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: tela > 993 ? '0px' : '80px'
        }}
      >
        <Button
          size="sm"
          style={{ width: "200px" }}
          variant="primary"
          onClick={() => history.push("/dashboards")}
        >
          Dashboards
        </Button>
      </div>

      
    </ProSidebar>
  );
};
export default Aside;

const ViewBar = styled.div`
  position: absolute;
  right: 0px;
  top: 40%;
  visibility: hidden;

  @media (min-width: 768px) {
    .bar {
      visibility: visible;
    }
  }
`;
const SideBarTitle = styled.div` 
            padding: 24px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 14px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 40px;
            
 @media (max-width: 400px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 12px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    } 
    
 @media (max-width: 500px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 12px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }
     @media (max-width: 600px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }    
    @media (max-width: 700px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }    
    @media (max-width: 800px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }   
     @media (max-width: 900px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }
   @media (max-width: 1000px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }
   @media (max-width: 1100px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }
   @media (max-width: 1200px) {
            padding: 10px;
            text-Transform: uppercase;
            font-Weight: bold;
            font-Size: 18px;
            letter-Spacing: 1px;
            overflow: hidden;
            text-Overflow: ellipsis;
            white-Space: nowrap;
            display: flex;
            align-Items: center;
            justify-Content: flex-start;
            padding-Top: 20px;
    }


`;