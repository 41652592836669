import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Input, Select, SelectProps } from 'antd';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import Usuarios from './Usuarios';
import Sensores from './Sensores';
import { GetBranches } from '../UsuarioAdmin/apiUser';



interface IAddUser {
    GroupName: string,
    users: string,

    targetKeysSensors: string[],
    targetKeys: string[],

    sensors: string,
    branchId: string,
    setName: Dispatch<SetStateAction<any>>,
    setUsers: Dispatch<SetStateAction<any>>,
    setSensors: Dispatch<SetStateAction<any>>,

    setTargetKeysSensors: Dispatch<SetStateAction<any>>,
    setTargetKeys: Dispatch<SetStateAction<any>>,

    setGroupName: Dispatch<SetStateAction<any>>,
    setBranchId: Dispatch<SetStateAction<any>>,

}


export default function AdicionarUsuario({
    GroupName,

    targetKeysSensors,
    targetKeys,
    branchId,

    setTargetKeysSensors,
    setTargetKeys,
    setGroupName,
    setBranchId
}
    : IAddUser
) {
    const [branches, setBranches] = useState<any>(null)


    useEffect(() => {
        GetBranches(setBranches)
    }, [])


    useEffect(() => {
        setTargetKeysSensors([]);
        setTargetKeys([]);
        setGroupName('');
        setBranchId('');

    }, [])

    const options: SelectProps['options'] = branches?.map((value: any) => {
        return { value: value.id, label: value.name }
    });



    return (<div>



        <Input
            value={GroupName}
            onChange={(ev: any) => { setGroupName(ev.target.value) }}
            placeholder="Enter your group name"
            prefix={<AiOutlineUsergroupAdd className="site-form-item-icon" />}
        />

        <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select the company"
            value={branchId}
            onChange={(ev) => {
                setBranchId(ev)
            }}
            options={options}
        />


        <div style={{
            display: 'flex',
            margin: '10px 0px 0px 0px',
            flexDirection: 'column',

        }}>
            <h6>Usuários</h6>
            {/* @ts-ignore */}
            <Usuarios setTargetKeys={setTargetKeys} targetKeys={targetKeys} />


        </div>

        <div style={{
            display: 'flex',
            margin: '10px 0px 0px 0px',
            flexDirection: 'column',

        }}>
            <h6>Sensores</h6>
            {/* @ts-ignore */}
            <Sensores setTargetKeysSensors={setTargetKeysSensors} targetKeysSensors={targetKeysSensors} />


        </div>




    </div>)
}