import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input } from 'antd';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { GetOneGroup } from './apiGrupo';
import Usuarios from './Usuarios';
import Sensores from './Sensores';



interface IAddUser {
    groupName: string,
    setGroupName: Dispatch<SetStateAction<any[]>>,
    idGroup: string,

    targetKeys: any,
    setTargetKeys: Dispatch<SetStateAction<any[]>>

    targetKeysSensors: any,
    setTargetKeysSensors: Dispatch<SetStateAction<any[]>>

}


export default function EditarGrupo({
    idGroup,

    groupName,
    setGroupName,

    targetKeys,
    setTargetKeys,

    setTargetKeysSensors,
    targetKeysSensors
}
    : IAddUser
) {

    const [groupEdit, setGroupEdit] = useState<any>(null);


    const getData = () => {
        GetOneGroup(idGroup, setGroupEdit)
    }

    useEffect(() => {
        getData()
    }, [idGroup])


    useEffect(() => {
        setGroupName(groupEdit?.name)
    }, [groupEdit])





    return (<div>



        <Input
            value={groupName}
            onChange={(ev: any) => { setGroupName(ev.target.value) }}
            placeholder="Enter your username"
            prefix={<AiOutlineUsergroupAdd className="site-form-item-icon" />}
        />

        <div style={{
            display: 'flex',
            margin: '10px 0px 0px 0px',
            flexDirection: 'column',

        }}>
            <h6>Usuários</h6>
            <Usuarios setTargetKeys={setTargetKeys} targetKeys={targetKeys} usersSelected={groupEdit} />
            <h6 style={{margin: '10px 0px 0px 0px'}}>Sensores</h6>
            <Sensores
                setTargetKeysSensors={setTargetKeysSensors}
                targetKeysSensors={targetKeysSensors}
                sensorsSelected={groupEdit} />
        </div>


    </div>)
}