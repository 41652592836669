import React from "react";
import { Checkbox, Radio, Tag, Typography, theme, Spin, Card } from "antd";

import {
  Button,
  ColorPicker,
  ConfigProvider,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
} from "antd";
import type { Color } from "antd/es/color-picker";
import { useLocalStorageState } from "ahooks";

type ThemeData = {
  borderRadius: number;
  colorPrimary: string;
  Button?: {
    colorPrimary: string;
    algorithm?: boolean;
  };
};

const defaultData: ThemeData = {
  borderRadius: 6,
  colorPrimary: "#1677ff",
  Button: {
    colorPrimary: "#00B96B",
  },
};

export const Configuracao = () => {
  const [form] = Form.useForm();
  const [data, setData] = React.useState<ThemeData>(defaultData);
  const [themeState, setThemeState] = useLocalStorageState<ThemeData>(
    "theme-config",
    {
      defaultValue: {
        colorPrimary: "#1677ff",
        borderRadius: 6,
        Button: {
          colorPrimary: "#00B96B",
        },
      },
    }
  );

  // console.log("themeState", themeState);
  return (
    <>
      <div>
        <Form
          form={form}
          onValuesChange={(_, allValues) => {
            setData({
              ...allValues,
            });
          }}
          name="theme"
          initialValues={defaultData}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            name="colorPrimary"
            label="Primary Color"
            trigger="onChangeComplete"
            getValueFromEvent={(color: Color) => color.toHexString()}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item name="borderRadius" label="Border Radius">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Button">
            <Form.Item
              name={["Button", "algorithm"]}
              valuePropName="checked"
              label="algorithm"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={["Button", "colorPrimary"]}
              label="Primary Color"
              trigger="onChangeComplete"
              getValueFromEvent={(color: Color) => color.toHexString()}
            >
              <ColorPicker />
            </Form.Item>
          </Form.Item>
          <Form.Item name="submit" wrapperCol={{ offset: 4, span: 20 }}>
            <Button type="primary">Submit</Button>
          </Form.Item>
          <button
            style={{ margin: "0 8px" }}
            type="button"
            onClick={() => setThemeState(defaultData)}
          >
            Reset
          </button>
        </Form>
      </div>

      <Divider />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: data.colorPrimary,
            borderRadius: data.borderRadius,
          },
          components: {
            Button: {
              colorPrimary: data.Button?.colorPrimary,
              algorithm: data.Button?.algorithm,
            },
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBlock: 32,
            rowGap: 32,
          }}
        >
          <Space>
            <Input />
            <Button type="primary">Button</Button>
          </Space>

          <Checkbox.Group
            options={new Array(3)
              .fill(null)
              .map((_, index) => `option-${index}`)}
          />

          <Radio.Group
            defaultValue="default"
            options={["default", "apple", "orange"]}
          />

          <Space>
            <Button type="primary">Primary</Button>
            <Button>Default</Button>
            <Button type="dashed">Dashed</Button>
            <Button type="primary" danger>
              Danger
            </Button>
            <Button type="text">Text</Button>
            <Button type="link">Link</Button>
          </Space>

          {/* CheckedTag has bug that not support CP className yet */}
          {/* <Space>
                  <Switch
                    checkedChildren="So Geek"
                    unCheckedChildren="So Geek"
                    defaultChecked
                  />
                  <Tag onClick={() => {}}>Tag</Tag>
                  {new Array(3).fill(null).map((_, index) => (
                    <CheckableTag
                      key={index}
                      checked={checkedTags.includes(index)}
                      onChange={(checked) => {
                        setCheckedTags((tags) => {
                          if (checked) {
                            return [...tags, index];
                          }
                          return tags.filter((t) => t !== index);
                        });
                      }}
                    >
                      Tag {index}
                    </CheckableTag>
                  ))}
                </Space> */}

          <Space>
            <Spin size="small" />
            <Spin />
            <Spin size="large" />
          </Space>

          <Card title="Card Title" size="small" style={{ minWidth: 300 }}>
            Hello, <span>Geek Theme</span>
          </Card>
        </div>
      </ConfigProvider>
    </>
  );
};
