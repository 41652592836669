
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import ModalGeral from '../../../components/Modal';
import AdicionarUsuário from './AdicionarUsuario';
import EditarUausario from './EditarUsuario';
import { CreateUser, DeleteUser, EditUser, GetUser, getAllUsersByBranch } from './apiUser';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdRadioButtonUnchecked } from 'react-icons/md';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import { WindowWidth, fontSizeResponsive, iconResponsive } from '../../../components/WidthXHeigth';



export default function UsuarioAdmin() {
    interface DataType {
        key: string,
        name: string,
        id: number,
        email: string,
        admin: boolean,
        cardId: string

    }

    type DataIndex = keyof DataType;
    const [user, setUser] = useState<any>(null);
    const [idUser, setIdUser] = useState<any>(null);
    const [nameUser, setNameUser] = useState('');
    const [email, setEmail] = useState('');
    const [passwordUser, setPasswordUser] = useState('');
    const [admin, setAdmin] = useState(false);
    const [branch, setBranch] = useState<any>([]);
    const [cardId, setCardId] = useState<any>([]);

    const branchUser = window.localStorage.getItem('branchId')
    const [allUsersByBranch, setAllUsersByBranch] = useState<any>();

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [openClose, setOpenClose] = useState<boolean>(false);
    const [options, setOptions] = useState<string>('');
    const [modalTitle, setModalTitle] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const modalChildren: { [key: string]: JSX.Element } = {
        adicionar: <AdicionarUsuário
            name={nameUser}
            email={email}
            password={passwordUser}
            admin={admin}
            branch={branch}
            cardId={cardId}

            setName={setNameUser}
            setEmail={setEmail}
            setPassword={setPasswordUser}
            setAdmin={setAdmin}
            setBranch={setBranch}
            setCardId={setCardId}
        />,
        editar: <EditarUausario
            name={nameUser}
            email={email}
            password={passwordUser}
            admin={admin}
            branch={branch}
            setCardId={setCardId}
            cardId={cardId}

            setBranch={setBranch}
            setName={setNameUser}
            setEmail={setEmail}
            setPassword={setPasswordUser}
            setAdmin={setAdmin}
            idUser={idUser}

        />
    }

    const getData = () => {
        GetUser(setUser)
        getAllUsersByBranch(branchUser, setAllUsersByBranch)


    }
    useEffect(() => {
        getData()
    }, [])
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block', fontSize: fontSizeResponsive }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined rev={1} />}
                        size="small"
                        style={{ width: 90, fontSize: fontSizeResponsive }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90, fontSize: fontSizeResponsive }}
                    >
                        Reset
                    </Button>
                    <Button
                        style={{ fontSize: fontSizeResponsive }}
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        style={{ fontSize: fontSizeResponsive }}
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined rev={2} style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'fontResponsive',
            ...getColumnSearchProps('name'),
            render: (_, record) => (
                <div style={{
                    width: WindowWidth * 0.2,
                    fontSize: fontSizeResponsive
                }}>
                    {record.name}
                </div>
            )

        },
        {
            title: 'Card',
            dataIndex: 'cardId',
            key: 'cardId',
            className: 'fontResponsive',
            ...getColumnSearchProps('cardId'),
            render: (_, record) => (
                <div style={{
                    width: WindowWidth * 0.05,
                    fontSize: fontSizeResponsive
                }}>
                    {record.cardId}
                </div>
            )

        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            className: 'fontResponsive',
            ...getColumnSearchProps('email'),
            render: (_, record) => (
                <div style={{
                    width: WindowWidth * 0.2,
                    fontSize: fontSizeResponsive
                }}>
                    {record.email}
                </div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            className: 'fontResponsive',
            // ...getColumnSearchProps('action'),
            render: (_, record) => (
                // console.log(record)
                <Space size="middle">
                    <a
                        style={{
                            fontSize: iconResponsive
                        }}
                        onClick={() => {
                            setIdUser(record.id)
                            setOptions('editar');
                            setOpenClose(!openClose);
                            setModalTitle('Editar usuário')
                        }}>{<BsPencilSquare />}</a>
                    <a
                        style={{

                            fontSize: iconResponsive
                        }}
                        onClick={() => {
                            setIdUser(record.id)
                            setOptions('deletar');
                            setOpenClose(!openClose);
                            setModalTitle('Tem certeza que deseja deletar este usuário?')
                            setNameUser(record.name)
                        }}
                    >{<BsTrash />}</a>
                </Space>
            ),

        },
    ];

    const handleCreate = () => {
        const create = {
            cardId: cardId,
            name: nameUser,
            email: email,
            password: passwordUser,
            admin: admin,
            branches: { "connect": [{ id: branchUser }] }
        }


        CreateUser(create,
            () => {

                getData();
            },
            () => {
                getData();
            }
        )
    }
    const handleEdit = () => {
        const edit: any = {
            cardId: cardId,
            name: nameUser,
            email: email,
            admin: admin,
            branches: { "connect": [{ id: branchUser }] }
        }
        EditUser(
            idUser,
            edit,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }
    const handleDelete = () => {
        DeleteUser(idUser, () => {
            getData()
        }, () => {
            getData()
        }
        )
    }
    const data: DataType[] = allUsersByBranch?.map((value: any, index: any) => {

        return {
            key: index + 1,
            name: value.name,
            id: value.id,
            email: value.email,
            admin: value.admin,
            cardId: value.cardId
        }
    })




    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Button
                    type="primary" style={{ margin: '10px', fontSize: fontSizeResponsive }}
                    onClick={() => {
                        setOpenClose(!openClose);
                        setModalTitle('Adicionar usuário');
                        setOptions('adicionar');
                        getData();
                    }}
                >Adicionar usuário</Button>
            </div>


            <Table
                columns={columns}
                dataSource={data?.filter((value: any) => {
                    return value.admin !== true
                })} />



            <ModalGeral

                title={modalTitle}
                confirm={() => {
                    if (options == 'adicionar') { return handleCreate(), setOpenClose(!openClose), getData() }
                    if (options == 'editar') { return handleEdit(), setOpenClose(!openClose), getData() }
                    if (options == 'deletar') { return handleDelete(), setOpenClose(!openClose), getData() }

                    setAdmin(false);
                    setEmail('');
                    setNameUser('');
                    setPasswordUser('');
                    setBranch([])

                }}
                cancel={() => {
                    setOpenClose(!openClose);
                    getData();
                    if (options == 'adicionar') {
                        setAdmin(false);
                        setEmail('');
                        setNameUser('');
                        setPasswordUser('');
                        setBranch([]);
                        setCardId('')
                    }


                }}
                isModalOpen={openClose}
                children={options == 'deletar' ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                        <Button value="large">{nameUser}</Button>
                    </div>
                    : modalChildren[options]}
            />

        </div>
    )
}

