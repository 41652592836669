
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import ModalGeral from '../../../components/Modal';
import { CreateEmpresa, DeleteEmpresa, EditEmpresa, GetEmpresa } from './apiEmpresa';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';

import AdicionarEmpresa from './AdicionarEmpresa';
import EditarEmpresa from './EditarEmpresa';


export default function Usuario() {
    interface DataType {
        key: string,
        name: string,
        id: any,


    }

    type DataIndex = keyof DataType;
    const [name, setName] = useState<any>('');
    const [empresa, setEmpresa] = useState<any>(null);
    const [empresaId, setEmpresaId] = useState<any>(null);


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [openClose, setOpenClose] = useState<boolean>(false);
    const [options, setOptions] = useState<string>('');
    const [modalTitle, setModalTitle] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const modalChildren: { [key: string]: JSX.Element } = {
        adicionar: <AdicionarEmpresa
            name={name}
            setName={setName}
        />,
        editar: <EditarEmpresa
            name={name}
            setName={setName}
            empresaId={empresaId}
        />
    }

    const getData = () => {
        GetEmpresa(setEmpresa)
    }

    useEffect(() => {
        getData()
    }, [])



    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined rev={1} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined rev={2} style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name'),

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '3%',
            // ...getColumnSearchProps('action'),
            render: (_, record) => (
                // console.log(record)
                <Space size="large">
                    <a onClick={() => {
                        setEmpresaId(record.id)
                        setOptions('editar');
                        setOpenClose(!openClose);
                        setModalTitle('Editar empresa')
                    }}>{<BsPencilSquare />}</a>
                    <a
                        onClick={() => {
                            setEmpresaId(record.id)
                            setOptions('deletar');
                            setOpenClose(!openClose);
                            setModalTitle('Tem certeza que deseja deletar esta empresa?')
                            setName(record.name)
                        }}
                    >{<BsTrash />}</a>
                </Space>
            ),

        },
    ];



    const handleCreate = () => {
        const create = {
            name: name,

        }
        CreateEmpresa(create,
            () => {
                getData();
                setName('');

            },
            () => {
                getData();
            }
        )
    }

    const handleEdit = () => {
        const edit: any = {
            name: name,

        }
        EditEmpresa(
            empresaId,
            edit,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }
    const handleDelete = () => {
        DeleteEmpresa(empresaId, () => {
            getData()
        }, () => {
            getData()
        }
        )
    }


    const data: DataType[] = empresa?.map((value: any, index: any) => {
        return {
            key: index + 1,
            name: value.name,
            id: value.id,
        }
    })


    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Button
                    type="primary" style={{ margin: '10px' }}
                    onClick={() => {
                        setOpenClose(!openClose);
                        setModalTitle('Adicionar empresa');
                        setOptions('adicionar');
                    }}
                >Adicionar empresa</Button>
            </div>

            <Table columns={columns} dataSource={data} />

            <ModalGeral

                title={modalTitle}
                confirm={() => {
                    if (options == 'adicionar') { return handleCreate(), setOpenClose(!openClose), getData() }
                    if (options == 'editar') { return handleEdit(), setOpenClose(!openClose) }
                    if (options == 'deletar') { return handleDelete(), setOpenClose(!openClose) }

                }}
                cancel={() => {
                    setOpenClose(!openClose)

                }}
                isModalOpen={openClose}
                children={options == 'deletar' ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                        <Button value="large">{name}</Button>
                    </div>
                    : modalChildren[options]}
            />

        </div>
    )
}