import { Button } from 'antd';


export default function ViewPortas({ viewPortas }: any) {
// console.log(viewPortas)
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            {viewPortas.length !== 0 ? viewPortas?.map((value: any, index: any) => {
                return <Button key={index}>{value.name}</Button>
            }) : 'Este grupo não possui sensores'}
        </div>)

}