import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Input, Radio, Select } from 'antd';
import type { SelectProps } from 'antd';
import { GetUser } from '../Admin/UsuarioAdmin/apiUser';
import { getAllUsersByBranch } from '../Acesso/Usuario/apiUser';






interface IAddCard {
    userId: any,
    setUserId: Dispatch<SetStateAction<any>>,

}


export default function CadastrarCartao({
    userId,
    setUserId,


}
    : IAddCard
) {

    const [users, serUsers] = useState<any>(null)
    const branchId = window.localStorage.getItem('branchId')


    useEffect(() => {

    }, [])


    useEffect(() => {
        getAllUsersByBranch(branchId, serUsers)
    }, [])






    const options: SelectProps['options'] = users?.filter((values: any) => {
        return values.admin !== true
    }).map((value: any) => {
        return { value: value.id, label: value.name }
    });




    return (<div>


        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select the user"
            value={userId}
            onChange={(ev) => {

                setUserId(ev)
            }}
            options={options}
        />



    </div>)
}