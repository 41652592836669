import axios from "axios";

import { Dispatch, SetStateAction } from "react";
import { HISTORY } from "../../constains";

const token = window.localStorage.getItem("accessToken");
const access = {
    headers: {
        Authorization: "Bearer " + token,
    },
};

export const GetHistory = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${HISTORY}`, access)
        .then(res => {
            setState(res.data.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}