
import React from 'react';
import { Modal } from 'antd';
import { ScrollBar } from '../Layout';


interface IModalGeral {
    title: string;
    children: React.ReactNode;
    isModalOpen: boolean;
    cancel: () => void;
    confirm: () => void
}

const ModalGeral = ({ title, children, isModalOpen, cancel, confirm }: IModalGeral) => {


    return (
        <>

            <Modal title={title} open={isModalOpen} onOk={confirm} onCancel={cancel} >
                <ScrollBar>
                    <div
                        className='TheScroll'
                        style={{ overflowY: 'scroll', maxHeight: '300px' }}
                    >

                        {children}
                    </div>
                </ScrollBar>
            </Modal>
        </>
    );
};

export default ModalGeral;



