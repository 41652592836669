/* eslint-disable no-unused-vars */

import Aside from ".";

export default function DashView({ children }: any) {
  return (
    <>
      <Aside>
        {children}
      </Aside>
    </>
  )
}


