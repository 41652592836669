import { Button, Space } from 'antd';
import React from 'react';

export default function Home() {

    return (
        <div>

            <Space>
              Home
            </Space>

        </div>
    )
}

