import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { CallNotification } from "../../../components/Alert";
import { LIST_MAC } from "../../../constains";
const token = window.localStorage.getItem("accessToken");
const access = {
    headers: {
        Authorization: "Bearer " + token,
    },
};


export const GetListMac = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${LIST_MAC}`, access)
        .then(res => {
            // console.log(res)
            setState(res.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}