import { Button } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { TiDelete } from "react-icons/ti";

import React from "react";
import { GetOneSensor } from "./ApiSensor";
import { DeleteDigital } from "../../Portas/ApiSensor";
import ModalGeral from "../../../components/Modal";

interface IAddCard {
  idSensor: any;
}

export default function DigView({ idSensor }: IAddCard) {
  const [modalTitle, setModalTitle] = React.useState<any>("");
  const [openClose, setOpenClose] = React.useState<any>(false);
  const [idDigital, setIdDigital] = React.useState<any>("");
  const [sensor, setSensor] = React.useState<any>("");
  const [nomeDig, setNomeDig] = React.useState<any>("");

  const getData = () => {
    GetOneSensor(idSensor, setSensor);
  };

  useEffect(() => {
    getData();
  }, [idSensor]);

  const handleDelete = (idDigital: any) => {
    DeleteDigital(sensor?.mac, idDigital);
    getData();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {sensor?.digitalUsers?.map((value: any) => (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Button
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {value?.user?.name || value?.user[0]?.name}{" "}
            <div
              style={{ height: "20px", width: "50px" }}
              onClick={() => {
                setModalTitle("Tem Certeza que deseja deletar essa digital?");
                setOpenClose(!openClose);
                setIdDigital(value?.id);
                setNomeDig(value?.user?.name || value?.user[0]?.name);
              }}
            >
              <TiDelete size={25} />
            </div>
          </Button>
        </div>
      ))}

      <ModalGeral
        title={modalTitle}
        confirm={() => {
          handleDelete(idDigital);
          setOpenClose(!openClose);
        }}
        cancel={() => {
          setOpenClose(!openClose);
        }}
        isModalOpen={openClose}
        children={
          <div>
            <>{nomeDig}</>
          </div>
        }
      />
    </div>
  );
}
