
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import ModalGeral from '../../../components/Modal';
import { CreateGroup, DeleteGroup, EditGroup, GetGroup } from './apiGrupo';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import AdicionarGrupo from './AdicionarGrupo';
import EditarGrupo from './EditarGrupo';
import { AiOutlineEye } from 'react-icons/ai';
import ViewUsers from './ViewUsers';
import ViewPortas from './ViewPortas';



export default function Grupos() {
    interface DataType {
        key: string,
        name: string,
        id: any,
        users: any,
        sensors: any,
    }

    type DataIndex = keyof DataType;
    const [groups, setGroups] = useState<any>(null);
    const [groupName, setGroupName] = useState<any>(null);
    const [users, setUsers] = useState<any>(null);
    const [idGroup, setIdGroup] = useState<any>(null);
    const [nameUser, setNameUser] = useState('');
    const [sensors, setSensors] = useState('');
    const [viewUsers, setViewUsers] = useState<any>(null);
    const [viewPortas, setViewPortas] = useState<any>(null);
    const [branchId, setBranchId] = useState<any>(null);


    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [targetKeysSensors, setTargetKeysSensors] = useState<string[]>([]);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [openClose, setOpenClose] = useState<boolean>(false);
    const [options, setOptions] = useState<string>('');
    const [modalTitle, setModalTitle] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const modalChildren: { [key: string]: JSX.Element } = {
        adicionar: <AdicionarGrupo
            GroupName={groupName}
            users={users}
            sensors={sensors}
            targetKeys={targetKeys}
            targetKeysSensors={targetKeysSensors}

            setTargetKeys={setTargetKeys}
            setTargetKeysSensors={setTargetKeysSensors}

            setName={setNameUser}
            setGroupName={setGroupName}
            setUsers={setUsers}
            setSensors={setSensors}
            branchId={branchId}
            setBranchId={setBranchId}

        />,
        editar: <EditarGrupo
            groupName={groupName}
            idGroup={idGroup}
            setGroupName={setGroupName}

            targetKeys={targetKeys}
            setTargetKeys={setTargetKeys}

            targetKeysSensors={targetKeysSensors}
            setTargetKeysSensors={setTargetKeysSensors}

            branchId={branchId}
            setBranchId={setBranchId}

        />,
        viewUsers: <ViewUsers viewUsers={viewUsers} />,
        viewPortas: <ViewPortas viewPortas={viewPortas} />,
    }

    const getData = () => {
        GetGroup(setGroups)
    }

    useEffect(() => {
        getData()
    }, [])




    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined rev={1} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined rev={2} style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<DataType> = [
        // Table.EXPAND_COLUMN,
        {
            title: 'Nome do grupo',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            ...getColumnSearchProps('name'),

        },


        {
            title: 'Usuários',
            dataIndex: 'users',
            key: 'users',
            width: '10%',
            ...getColumnSearchProps('users'),
            render: (_, record) => (
                <>
                    {/* @ts-ignore
                    {record?.users?.map((value: any) => {
                        return value.name
                    })} */}
                    <AiOutlineEye
                        style={{ cursor: 'pointer' }}

                        onClick={() => {
                            setViewUsers(record?.users)
                            setOpenClose(!openClose)
                            setOptions('viewUsers')
                            setModalTitle('Usuários');
                        }} />
                </>
            ),

        },
        {
            title: 'Portas',
            dataIndex: 'sensors',
            key: 'sensors',
            width: '10%',
            ...getColumnSearchProps('sensors'),
            render: (_, record) => (
                // console.log(record)
                <>
                    {/* @ts-ignore
                    {record?.users?.map((value: any) => {
                        return value.name
                    })} */}
                    <AiOutlineEye
                        style={{ cursor: 'pointer' }}

                        onClick={() => {
                            setViewPortas(record?.sensors)
                            setOpenClose(!openClose)
                            setOptions('viewPortas')
                            setModalTitle('Portas');
                        }} />
                </>
            ),

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '3%',
            // ...getColumnSearchProps('action'),
            render: (_, record) => (
                // console.log(record)
                <Space size="large">
                    <a onClick={() => {
                        setIdGroup(record.id)
                        setOptions('editar');
                        setOpenClose(!openClose);
                        setModalTitle('Editar grupo')
                    }}>{<BsPencilSquare />}</a>
                    <a
                        onClick={() => {
                            setIdGroup(record.id)
                            setOptions('deletar');
                            setOpenClose(!openClose);
                            setModalTitle('Tem certeza que deseja deletar este grupo?')
                            setNameUser(record.name)
                        }}
                    >{<BsTrash />}</a>
                </Space>
            ),

        },
    ];

    // console.log(targetKeysSensors)

    const handleCreate = () => {
        const create = {
            branchId: branchId,
            name: groupName,
            users: {
                "connect": targetKeys.map((value: any) => {
                    return { id: value }
                })
            },
            sensors: {
                "connect": targetKeysSensors.map((value: any) => {
                    return { id: value.toString() }
                })
            }
        }

        CreateGroup(create,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }
    const handleEdit = () => {
        const edit: any =
        {
            branchId: branchId,
            name: groupName,
            users: {
                "set": targetKeys.map((value: any) => { return { id: value?.toString() } })
            },
            sensors: {
                "set": targetKeysSensors.map((value: any) => { return { id: value } })
            }
        }

        EditGroup(
            idGroup,
            edit,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }
    const handleDelete = () => {
        DeleteGroup(idGroup, () => {
            getData()
        }, () => {
            getData()
        }
        )
    }


    const data: DataType[] = groups?.map((value: any, index: any) => {
        // console.log(value)
        return {
            key: index + 1,
            name: value.name,
            id: value.id,
            users: value.users,
            sensors: value.sensors

        }
    })

    // console.log(data)

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Button
                    type="primary" style={{ margin: '10px' }}
                    onClick={() => {
                        setOpenClose(!openClose);
                        setModalTitle('Adicionar grupo');
                        setOptions('adicionar');
                    }}
                >Adicionar grupo</Button>
            </div>

            <Table columns={columns} dataSource={data}

            />

            <ModalGeral
                title={modalTitle}
                confirm={() => {
                    if (options == 'adicionar') { return handleCreate(), setOpenClose(!openClose) }
                    if (options == 'editar') { return handleEdit(), setOpenClose(!openClose) }
                    if (options == 'deletar') { return handleDelete(), setOpenClose(!openClose) }
                    if (options == 'viewUsers') { return setOpenClose(!openClose) }
                    if (options == 'viewPortas') { return setOpenClose(!openClose) }

                }}
                cancel={() => {
                    setOpenClose(!openClose)
                    if (options == 'adicionar') {
                        setTargetKeysSensors([]);
                        setTargetKeys([]);
                        setGroupName('');
                        setBranchId('');
                    }

                }}
                isModalOpen={openClose}
                children={options == 'deletar' ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                        <Button value="large">{nameUser}</Button>
                    </div>
                    : modalChildren[options]}
            />

        </div>
    )
}