import axios from "axios";
import { EMPRESA, USER } from "../../../constains";
import { Dispatch, SetStateAction } from "react";
import { CallNotification } from "../../../components/Alert";
import { Token } from "../../../helpers/token";
const access = {
    headers: {
        Authorization: "Bearer " + Token.getToken(),
    },
};




export const CreateUser = (
    user: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .post(`${USER}`, user, access)
        .then(response => {
            sucessCallback()
            CallNotification("Sucesso", "Usuário cadastrado com sucesso!", "success");
        })
        .catch(error => {
            failureCallback()
            CallNotification("Error", "Falha ao cadastrar usuário!", "danger");
        });

}
export const GetUser = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${USER}`, access)
        .then(res => {
            // console.log(res.data.data)
            setState(res.data.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const GetUserWithKey = (
    chave: any,
    setState: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${USER}`, access)
        .then(res => {
            // console.log(res.data.data)
            setState({ [chave]: res.data.data });
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const GetBranches = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${EMPRESA}`, access)
        .then(res => {
            // console.log(res.data.data)
            setState(res.data.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const GetBranchesByUser = (
    id: any,
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${USER}/${id}`, access)
        .then(res => {
            // console.log(res.data.user.branches)
            setState(res.data.user.branches)

            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const GetOneUser = (
    id: string,
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${USER}/${id}`, access)
        .then(res => {
            // console.log(res.data.user)
            setState(res.data.user);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const EditUser = (
    id: string,
    user: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .put(`${USER}/${id}`, user, access)
        .then(res => {

            CallNotification("Sucesso", "Usuário editado com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao editar usuário!", "danger");
            failureCallback()
        });

}
export const DeleteUser = (
    id: string,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .delete(`${USER}/${id}`, access)
        .then(res => {

            CallNotification("Sucesso", "Usuário deletado com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao deletar usuário!", "danger");
            failureCallback()
        });

}

