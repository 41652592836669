import { Button, Layout, theme } from 'antd';
import { useHistory } from 'react-router-dom';
import { Token } from '../../helpers/token';
import { memo, useEffect, useMemo, useState } from 'react';
import { GetBranchesByUser } from '../Admin/UsuarioAdmin/apiUser';

export default function Dashboards() {
    const [list, setList] = useState<any>();
    const history = useHistory();

    const {
        token: { colorBgContainer = '#141414', },
    } = theme.useToken();



    // const { admin , id } = Token?.getTokenData();


    const getTokenData = useMemo(() => { return Token.getTokenData() }, [Token])

    const getData = () => {
        GetBranchesByUser(getTokenData?.id, setList)
    }

    useEffect(() => {
        getData()
    }, [getTokenData?.id]);

    if (Token.getToken() == null) {
         window.location.reload();
    }


    return (<>

        <Layout style={{ padding: '24px 0', background: colorBgContainer, height: '100%' }}>
            <Button style={{ fontSize: '20px', height: '40px' }} type='primary' >Dashboards</Button>

            {getTokenData?.admin == true ? (
                <div style={{
                    margin: '20px 0px 20px 0px', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Button
                        onClick={() => {
                            history.push("/admin");
                            window.localStorage.setItem("menu", "1");
                        }}
                    >
                        Admin
                    </Button>
                </div>
            ) : <></>}

            {list?.map((value: any, j: any) => {
                return <Button key={j}
                    onClick={() => {
                        history.push('/dashboard')
                        window.localStorage.setItem('branchId', value.id)
                        window.localStorage.setItem('menu', '1')
                        window.localStorage.setItem('empresa', `${value.name}`)

                    }}
                    type="default" style={{ margin: '10px' }}
                >{value.name}</Button>
            })}

        </Layout>
    </>)
}