
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import ModalGeral from '../../../components/Modal';
import AdicionarUsuário from './AdicionarUsuario';
import { CreateUser, DeleteUser, EditUser, GetBranches, GetUserWithKey } from './apiUser';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdRadioButtonUnchecked } from 'react-icons/md';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { useSetState } from 'ahooks';
import EditarUsuario from './EditarUsuario';


export default function UsuarioAdmin() {
    interface DataType {
        key: string,
        name: string,
        id: number,
        email: string,
        admin: boolean,
        cardId: string,
        branch: any

    }

    type DataIndex = keyof DataType;

    const [branches, setBranches] = useState<any>(null);
    const [userAdm, setUserAdm] = useSetState<any>({
        user: null,
        idUser: null,
        nameUser: '',
        email: '',
        passwordUser: '',
        admin: false,
        branch: [],
        cardId: [],
        searchText: '',
        searchedColumn: '',
        openClose: false,
        options: '',
        modalTitle: '',
    })
    const searchInput = useRef<InputRef>(null);

    const modalChildren: { [key: string]: JSX.Element } = {
        adicionar: <AdicionarUsuário
            name={userAdm.nameUser}
            email={userAdm.email}
            password={userAdm.passwordUser}
            admin={userAdm.admin}
            branch={userAdm.branch}
            cardId={userAdm.cardId}

            setUserAdm={setUserAdm}
        />,
        editar: <EditarUsuario
            name={userAdm.nameUser}
            email={userAdm.email}
            password={userAdm.passwordUser}
            admin={userAdm.admin}
            branch={userAdm.branch}
            cardId={userAdm.cardId}
            idUser={userAdm.idUser}

            setUserAdm={setUserAdm}
        />
    }



    const getData = () => {
        GetUserWithKey('user', setUserAdm)
        GetBranches(setBranches)
    }




    useEffect(() => {
        getData()
    }, [])
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setUserAdm({ searchText: selectedKeys[0] });
        setUserAdm({ searchedColumn: dataIndex });
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setUserAdm({ searchText: '' });
    };
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined rev={1} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setUserAdm({ searchText: (selectedKeys as string[])[0] });
                            setUserAdm({ searchedColumn: dataIndex });
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined rev={2} style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            userAdm.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[userAdm.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });





    const TheFilter = branches?.map((value: any) => {

        return {
            text: value.name, value: value.name
        }

    })




    const columns: ColumnsType<DataType> = [
        {
            title: 'Empresas',
            dataIndex: 'branch',
            key: 'branch',
            width: '20%',
            filters: TheFilter,
            // @ts-ignore
            onFilter: (value: string, record) => {
               

                return(record.branch.includes(value))},
            // ...getColumnSearchProps('branch'),
            render: (_, record) => (
                // console.log(record.branch)
                <Space size="large">
                    {record.branch}

                </Space>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnSearchProps('name'),

        },
        {
            title: 'CardId',
            dataIndex: 'cardId',
            key: 'cardId',
            width: '20%',
            ...getColumnSearchProps('cardId'),

        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '15%',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Admin',
            dataIndex: 'admin',
            key: 'admin',
            width: '5%',
            ...getColumnSearchProps('admin'),
            render: (_, record) => (

                <Space size="large">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px'

                    }}>

                        <a >{record.admin == true ? <AiOutlineCheckCircle /> : <MdRadioButtonUnchecked />}</a>
                    </div>

                </Space>
            ),
        },
        // {
        //     title: 'Address',
        //     dataIndex: 'address',
        //     key: 'address',
        //     ...getColumnSearchProps('address'),
        //     sorter: (a, b) => a.address.length - b.address.length,
        //     sortDirections: ['descend', 'ascend'],
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '3%',
            // ...getColumnSearchProps('action'),
            render: (_, record) => (
                // console.log(record)
                <Space size="large">
                    <a onClick={() => {
                        setUserAdm({ idUser: record.id })
                        setUserAdm({ options: 'editar' });
                        setUserAdm({ openClose: !userAdm.openClose });
                        setUserAdm({ modalTitle: 'Editar usuário' })
                    }}>{<BsPencilSquare />}</a>
                    <a
                        onClick={() => {
                            setUserAdm({ idUser: record.id })
                            setUserAdm({ options: 'deletar' })
                            setUserAdm({ openClose: !userAdm.openClose });
                            setUserAdm({ modalTitle: 'Tem certeza que deseja deletar este usuário?' })
                            setUserAdm({ nameUser: record.name })
                        }}
                    >{<BsTrash />}</a>
                </Space>
            ),

        },
    ];





    const handleCreate = () => {
        const create = {
            cardId: userAdm.cardId,
            name: userAdm.nameUser,
            email: userAdm.email,
            password: userAdm.passwordUser,
            admin: userAdm.admin,
            branches: { "connect": userAdm.branch.map((value: any) => { return { id: value } }) }
        }


        CreateUser(create,
            () => {

                getData();
                setUserAdm({ admin: false, email: '', nameUser: '', passwordUser: '', branch: [], cardId: null });
            },
            () => {
                getData();
            }
        )
    }
    const handleEdit = () => {
        const edit: any = {
            cardId: userAdm.cardId,
            name: userAdm.nameUser,
            email: userAdm.email,
            admin: userAdm.admin,
            branches: { "set": userAdm.branch.map((value: any) => { return { id: value } }) }
        }
        EditUser(
            userAdm.idUser,
            edit,
            () => {
                getData();
            },
            () => {
                getData();
            }
        )
    }
    const handleDelete = () => {
        DeleteUser(userAdm.idUser, () => {
            getData()
        }, () => {
            getData()
        }
        )
    }



    const data: DataType[] = userAdm.user?.map((value: any, index: any) => {



        return {
            key: index + 1,
            name: value.name,
            id: value.id,
            email: value.email,
            admin: value.admin,
            cardId: value.cardId,
            branch: value.branches.map((value: any) => { return value.name })
        }

    })

    

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Button
                    type="primary" style={{ margin: '10px' }}
                    onClick={() => {
                        setUserAdm({ openClose: !userAdm.openClose, modalTitle: 'Adicionar usuário', options: 'adicionar' });
                        getData();
                    }}
                >Adicionar usuário</Button>
            </div>

            <Table columns={columns} dataSource={data} />

            <ModalGeral

                title={userAdm.modalTitle}
                confirm={() => {
                    if (userAdm.options == 'adicionar') { return handleCreate(), setUserAdm({ openClose: !userAdm.openClose }), getData() }
                    if (userAdm.options == 'editar') { return handleEdit(), setUserAdm({ openClose: !userAdm.openClose }), getData() }
                    if (userAdm.options == 'deletar') { return handleDelete(), setUserAdm({ openClose: !userAdm.openClose }), getData() }

                    setUserAdm({ admin: false, email: '', nameUser: '', passwordUser: '', branch: [], cardId: null });

                }}
                cancel={() => {
                    setUserAdm({ openClose: !userAdm.openClose })
                    getData();
                    if (userAdm.options == 'adicionar') {
                        setUserAdm({ admin: false, email: '', nameUser: '', passwordUser: '', branch: [], cardId: null });

                    }


                }}
                isModalOpen={userAdm.openClose}
                children={userAdm.options == 'deletar' ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                        <Button value="large">{userAdm.nameUser}</Button>
                    </div>
                    : modalChildren[userAdm.options]}
            />

        </div>
    )
}