import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Input, Select, SelectProps } from 'antd';
import { GetOneSensor } from './ApiSensor';
import { MdOutlinePlace, MdOutlineSensors, MdSensors } from 'react-icons/md';
import { IoLocationOutline } from 'react-icons/io5';
import { VscVmActive } from 'react-icons/vsc';
import { GetBranches } from '../UsuarioAdmin/apiUser';


interface IAddSensor {
    name: string,
    local: string,
    mac: string,
    branchId: string,
    setName: Dispatch<SetStateAction<any>>,
    setLocal: Dispatch<SetStateAction<any>>,
    setMac: Dispatch<SetStateAction<any>>,
    setBranchId: Dispatch<SetStateAction<any>>,
    idSensor: string
}


export default function EditarSensor({
    name,
    mac,
    local,
    branchId,
    setName,
    setMac,
    setLocal,
    setBranchId,
    idSensor,

}
    : IAddSensor
) {

    const [sensorEdit, setSensorEdit] = useState<any>(null);
    const [branches, setBranches] = useState<any>(null)

    const getData = () => {
        GetOneSensor(idSensor, setSensorEdit)
    }

    useEffect(() => {
        getData()
    }, [idSensor])

    useEffect(() => {
        GetBranches(setBranches)
    }, [])

    useEffect(() => {
        setName(sensorEdit?.name)
        setLocal(sensorEdit?.local)
        setMac(sensorEdit?.mac)
        setBranchId(sensorEdit?.branchId)
    }, [sensorEdit])

    const options: SelectProps['options'] = branches?.map((value: any) => {
        return { value: value.id, label: value.name }
    });



    return (<div>



        <Input
            value={name}
            onChange={(ev: any) => { setName(ev.target.value) }}
            placeholder="Enter your username"
            prefix={<MdSensors className="site-form-item-icon" />}

        />
        <Input
            value={local}
            onChange={(ev: any) => { setLocal(ev.target.value) }}
            placeholder="Enter your place"
            prefix={<MdOutlinePlace className="site-form-item-icon" />}

        />
        <Input
            value={mac}
            onChange={(ev: any) => { setMac(ev.target.value) }}
            placeholder="Enter your mac"
            prefix={<VscVmActive className="site-form-item-icon" />}

        />
        <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select the company"
            value={branchId}
            onChange={(ev) => {
                setBranchId(ev)
            }}
            options={options}
        />




    </div>)
}