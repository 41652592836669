import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DashView from "../components/Layout/DashView";
import DashViewAdmin from "../components/Layout/admin/DashView";
import Login from "../views/Login/Login";
import { PrivateRoute, ProvideAuth } from "./ProvideAuth";
import Home from "../views/Home";
import Usuario from "../views/Acesso/Usuario";
import Portas from "../views/Portas";
import Historico  from "../views/Historico";
import Dashboards from "../views/Dashboards";
import { Configuracao } from "../views/Configuracao";
import Grupos from "../views/Acesso/Grupos";
import UsuarioAdmin from "../views/Admin/UsuarioAdmin";
import EmpresaAdmin from "../views/Admin/EmpresaAdmin";
import PortasAdmin from "../views/Admin/PortasAdmin";
import GruposAdmin from "../views/Admin/GruposAdmin";
import  HistoricoAdmin  from "../views/Admin/HistoricoAdmin";
import ListMac from "../views/Admin/ListMac";

interface IRouterProps {
  setTheme: any;
}

export default function Router({ setTheme }: IRouterProps): React.ReactElement {
  return (
    <ProvideAuth>
      <BrowserRouter>
        {/* <BrowserRouter basename="/123backup.com.br"> */}
        <Switch>
          <Route exact path="/" render={() => <Login />} />
          <PrivateRoute path="/dashboards">
            <Dashboards />
          </PrivateRoute>
          <PrivateRoute path="/dashboard">
            <DashView>
              <Home />
            </DashView>
          </PrivateRoute>
          <PrivateRoute path="/usuarios">
            <DashView>
              <Usuario />
            </DashView>
          </PrivateRoute>
          <PrivateRoute path="/grupos">
            <DashView>
              <Grupos />
            </DashView>
          </PrivateRoute>
          <PrivateRoute path="/portas">
            <DashView>
              <Portas />
            </DashView>
          </PrivateRoute>
          <PrivateRoute path="/historico">
            <DashView>
              <Historico />
            </DashView>
          </PrivateRoute>
          <PrivateRoute path="/configuracao">
            <DashView>
              <Configuracao />
            </DashView>
          </PrivateRoute>

          {/* ADMIN */}

          <PrivateRoute path="/admin">
            <DashViewAdmin>

              <EmpresaAdmin />

            </DashViewAdmin>
          </PrivateRoute>

          <PrivateRoute path="/usuariosadmin">
            <DashViewAdmin>

              <UsuarioAdmin />

            </DashViewAdmin>
          </PrivateRoute>

          <PrivateRoute path="/gruposadmin">
            <DashViewAdmin>

              <GruposAdmin />

            </DashViewAdmin>
          </PrivateRoute>


          <PrivateRoute path="/portasadmin">
            <DashViewAdmin>

              <PortasAdmin />

            </DashViewAdmin>
          </PrivateRoute>

          <PrivateRoute path="/historicoadmin">
            <DashViewAdmin>

              <HistoricoAdmin />

            </DashViewAdmin>
          </PrivateRoute>

          <PrivateRoute path="/listofmac">
            <DashViewAdmin>

              <ListMac />

            </DashViewAdmin>
          </PrivateRoute>





        </Switch>
      </BrowserRouter>
    </ProvideAuth>
  );
}
