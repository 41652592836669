import React, { useEffect, useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Breadcrumb, Button, Layout, Menu, theme } from "antd";
import { useHistory } from "react-router-dom";
import { BsGearWideConnected, BsDoorOpen } from "react-icons/bs";
import { AiOutlineImport, AiOutlineHistory } from "react-icons/ai";
import { Token } from "../../helpers/token";
import styled from "styled-components";
import { WindowHeigth, WindowWidth, fontSizeResponsive } from "../WidthXHeigth";
import { AiOutlineMenu } from "react-icons/ai";
import Aside from "./Aside";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const { Header, Content, Footer, Sider } = Layout;

function Index({ children }: any) {
  const [menu, setMenu] = React.useState<boolean>(false);
  const history = useHistory();
  const [navigation, setNavigation] = React.useState<any>(null);
  const handleToggleSidebar = (value: any) => {
    setMenu(value);
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);



  const Options: { [key: string]: any } = {
    '/dashboard': 'Dashboard',
    '/usuarios': 'Usuários',
    '/grupos': 'Grupos',
    '/portas': 'Portas',
    '/historico': 'Histórico',
    '/configuracao': 'Configurações',
  };






  const {
    token: {
      colorBgContainer,
      colorBgElevated,
    },
  } = theme.useToken();

  const { admin, name, ...props2 } = Token.getTokenData();

  return (
    <Layout style={{ height: "100%" }}>


      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: colorBgElevated,
          justifyContent: 'space-between'
        }}
      >



        <div

          style={{
            display: windowSize.innerWidth > 992 ? 'none' : '',
            position: 'relative',
            // top: '0px',
            left: '-30px'
          }}>

          <AiOutlineMenu size={25} onClick={() => { setMenu(!menu) }} />

        </div>






        < div
          style={{

            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            fontSize: WindowWidth > 500 ? '15px' : '9px',
          }}
        >
          <div style={{ width: '250px' }}>bem vindo(a) {name}</div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: WindowWidth > 500 ? "30px" : '5px',

            }}

          >

            {/* <Button
              style={{ fontSize: fontSizeResponsive }}
              onClick={() => {
                history.push("/dashboards");
              }}
            >
              dashboards
            </Button> */}

            {admin ? (
              <>
                <Button
                  style={{ fontSize: fontSizeResponsive }}
                  onClick={() => {
                    history.push("/admin");
                    window.localStorage.setItem("menu", "1");
                  }}
                >
                  Admin
                </Button>
              </>
            ) : null}



            <Button
              style={{ fontSize: fontSizeResponsive }}
              onClick={() => {
                history.push("/");
                window.localStorage.setItem("menu", "1");
              }}
            >
              Sair
            </Button>
          </div>
        </div>
      </Header>

      <Layout
        style={{
          // padding: "20px 0",
          background: colorBgContainer,
          height: "100%",
        }}
      >




        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

          <div style={{ height: '100%' }}>

            <Aside
              tela={windowSize.innerWidth}
              setNavigation={setNavigation}
              image={false}
              collapsed={false}
              rtl={false}
              toggled={menu}
              handleToggleSidebar={handleToggleSidebar}
            />

          </div>



          <Content
            className="TheScroll"
            style={{
              padding: WindowWidth > 500 ? "0 30px" : '0px 0px',
              minHeight: 280,
              overflowY: "auto",
              // overflowX: "hidden",
              margin: "0px 0px 20px 0px",
              color: 'white'
            }}
          >

            {navigation == null ? Options[window.location.pathname] : navigation}


            <ScrollBar>{children}</ScrollBar>
          </Content>


        </div>

      </Layout>
      {/* </Content> */}

      <Footer
        style={{ textAlign: "center", padding: "1px" }}
      >
        {new Date().getFullYear()} made by - Arcanine
      </Footer>
    </Layout>
  );
}

export default Index;

export const ScrollBar = styled.div`
margin-top: 10px;
  .TheScroll {
    &::-webkit-scrollbar {
      width: 1px !important;
      height: 15px !important;
      -webkit-border-radius: 16px !important;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #0c0c0c !important;
      -webkit-border-radius: 3px !important;
    }

    &::-webkit-scrollbar-thumb:vertical {
      height: 5px !important;
      background-color: #f0e3e3 !important;
      -webkit-border-radius: 3px !important;
    }
  }
`;

