import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table, Transfer } from "antd";
import ModalGeral from "../../components/Modal";
import { CreateSensor, DeleteSensor, EditSensor, GetSensor } from "./ApiSensor";
import { BsDoorOpen, BsPencilSquare, BsTrash } from "react-icons/bs";
import { FiEye } from "react-icons/fi";
import { FaFingerprint } from "react-icons/fa";

import type { FilterConfirmProps } from "antd/es/table/interface";
import type { ColumnType, ColumnsType } from "antd/es/table";
import type { InputRef } from "antd";
import Usuarios from "./Usuarios";
import { GetOneUser, GetUser } from "../Acesso/Usuario/apiUser";
import { GetGroup } from "../Acesso/Grupos/apiGrupo";
import Grupos from "./Grupos";
import { OpenDoor } from "../Portas/ApiSensor";
import { Token } from "../../helpers/token";
import { CallNotification } from "../../components/Alert";
import { CreateDigital } from "../Admin/PortasAdmin/ApiSensor";
import Digital from "./Digital";
import DigitalView from "./Digital View";
import AdicionarSensor from "./AdicionarSensor";
import EditarSensor from "./EditarSensor";
import { WindowWidth, fontSizeResponsive, iconResponsive } from "../../components/WidthXHeigth";

export default function PortasAdmin() {
  interface DataType {
    key: string;
    name: string;
    id: number;
    local: string;
    usuarios: string;
    mac: string;
    group: string;
    digitalUsers: { id: string; user: any }[];
  }

  type DataIndex = keyof DataType;
  const [sensor, setSensor] = useState<any>(null);
  const [idSensor, setIdSensor] = useState<any>(null);
  const [name, setName] = useState("");
  const [mac, setMac] = useState<any>("");
  const [branchId, setBranchId] = useState<any>(null);
  const [usuarios, setUsuarios] = useState<any>(null);
  const [group, setGroup] = useState<any>(null);
  const [passwordSensor, setPasswordSensor] = useState("");
  const [local, setLocal] = useState("");
  const [cardId, setCardId] = useState<any>({});
  const [userId, setUserId] = useState<any>([]);

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [targetKeysGrupos, setTargetKeysGrupos] = useState<string[]>([]);
  const [digView, setDigView] = useState<any>([]);
  const [macDigital, setMacDigital] = useState<string>("");
  const [idOfList, setIdOfList] = useState<number>(0);

  const IdUser = Token.getTokenData().id;
  const branchUser = window.localStorage.getItem("branchId");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [openClose, setOpenClose] = useState<boolean>(false);
  const [options, setOptions] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const searchInput = useRef<InputRef>(null);
  const modalChildren: { [key: string]: JSX.Element } = {
    adicionar: (
      <AdicionarSensor
        name={name}
        local={local}
        mac={mac}
        branchId={branchId}
        setName={setName}
        setLocal={setLocal}
        setMac={setMac}
        setBranchId={setBranchId}
      />
    ),
    editar: (
      <EditarSensor
        name={name}
        local={local}
        mac={mac}
        branchId={branchId}
        setName={setName}
        setLocal={setLocal}
        setMac={setMac}
        setBranchId={setBranchId}
        idSensor={idSensor}
      />
    ),
    // usuarios: <Usuarios
    //     targetKeys={targetKeys}
    //     setTargetKeys={setTargetKeys}
    //     idSensor={idSensor}
    // />,
    group: (
      <Grupos
        targetKeysGrupos={targetKeysGrupos}
        setTargetKeysGrupos={setTargetKeysGrupos}
        idSensor={idSensor}
      />
    ),
    digital: <Digital userId={userId} setUserId={setUserId} />,
    digitalView: <DigitalView idSensor={idSensor} />,
  };
  const getData = () => {
    GetSensor(setSensor);
    GetUser(setUsuarios);
    GetGroup(setGroup);
    GetOneUser(IdUser, setCardId);
  };
  useEffect(() => {
    getData();
  }, []);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", fontSize: fontSizeResponsive }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev={1} />}
            size="small"
            style={{ width: 90, fontSize: fontSizeResponsive }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90, fontSize: fontSizeResponsive }}
          >
            Reset
          </Button>
          <Button
            style={{ fontSize: fontSizeResponsive }}
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            style={{ fontSize: fontSizeResponsive }}
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev={2}
        style={{ color: filtered ? "#1677ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      className: 'fontResponsive',
      ...getColumnSearchProps("name"),
      render: (_, record) => (
        <div style={{
          fontSize: fontSizeResponsive,
          maxWidth: `${WindowWidth * 0.1}px`,
          overflow: 'hidden',
          // display: "inline-block",
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {record.name}
        </div>
      )
    },

    {
      title: "Local",
      dataIndex: "local",
      key: "local",
      className: 'fontResponsive',
      // ...getColumnSearchProps("local"),
      render: (_, record) => (
        <div style={{
          fontSize: fontSizeResponsive,
          maxWidth: `${WindowWidth * 0.1}px`,
          overflow: 'hidden',
          // display: "inline-block",
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {record.local}
        </div>
      )
    },
    {
      title: "Digital",
      dataIndex: "digitalUsers",
      key: "digitalUsers",
      className: 'fontResponsive',
      // ...getColumnSearchProps("digitalUsers"),
      render: (_, record) => (

        <div
          style={{
            width: WindowWidth * 0.03,
            fontSize: iconResponsive,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}

          onClick={() => {
            setIdSensor(record.id);
            setOptions("digitalView");
            setOpenClose(!openClose);
            setModalTitle("Usuários cadastrados");
          }}
        >
          {<FiEye />}
        </div>

      ),
    },
    {
      title: "Grupos",
      dataIndex: "group",
      key: "group",
      className: 'fontResponsive',
      // ...getColumnSearchProps("group"),
      render: (_, record) => (
        <div
          style={{
            width: WindowWidth * 0.03,
            fontSize: iconResponsive,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setIdSensor(record.id);
            setOptions("group");
            setOpenClose(!openClose);
            setModalTitle("Editar grupo");
          }}
        >
          {<BsPencilSquare />}
        </div>

      ),
    },
    // {
    //   title: "Mac",
    //   dataIndex: "mac",
    //   key: "mac",
    //   className: 'fontResponsive',
    //   // ...getColumnSearchProps("mac"),
    //   render: (_, record) => (
    //     <div
    //       style={{
    //         alignItems: "center",
    //         justifyContent: "center",

    //         fontSize: fontSizeResponsive,
    //         maxWidth: `${WindowWidth * 0.15}px`,
    //         overflow: 'hidden',
    //         display: "inline-block",
    //         textOverflow: 'ellipsis',
    //         whiteSpace: 'nowrap',

    //       }}

    //     >
    //       {record.mac}
    //     </div>

    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: 'fontResponsive',
      render: (_, record) => {

        const lastNumber =
          record.digitalUsers.length > 0
            ? record.digitalUsers[record.digitalUsers.length - 1].id
            : 0;
        return (
          <div style={{ display: 'flex', flexDirection: 'row', width: WindowWidth * 0.2, justifyContent: 'space-between', alignItems: 'center' }}>
            <a
              style={{ fontSize: iconResponsive, marginRight: '4px' }}
              onClick={() => {
                // @ts-ignore
                setIdOfList(lastNumber + 1);
                setMacDigital(record.mac);
                setIdSensor(record.id);
                setOptions("digital");
                setOpenClose(!openClose);
                setModalTitle("Cadastrar digital");
              }}
            >
              {<FaFingerprint />}
            </a>

            <a
              style={{ fontSize: iconResponsive, marginRight: '4px' }}
              onClick={() => {
                setIdSensor(record.id);
                setOptions("editar");
                setOpenClose(!openClose);
                setModalTitle("Editar sensor");
              }}
            >
              {<BsPencilSquare />}
            </a>
            <a
              style={{ fontSize: iconResponsive, marginRight: '4px' }}
              onClick={() => {
                setIdSensor(record.id);
                setOptions("deletar");
                setOpenClose(!openClose);
                setModalTitle("Tem certeza que deseja deletar este sensor?");
                setName(record.name);
              }}
            >
              {<BsTrash />}
            </a>

            <Button
              style={{ fontSize: fontSizeResponsive, display: 'flex', justifyContent: 'center', alignItems: 'center', height: WindowWidth > 500 ? '' : '15px' }}
              onClick={() => {
                OpenDoor(
                  record.mac,
                  parseInt(cardId.cardId),
                  () => {
                    CallNotification(
                      "Sucesso",
                      "Porta aberta com sucesso!",
                      "success"
                    );
                  },
                  () => {
                    CallNotification(
                      "Error",
                      "Usuário sem permissão para abrir esta porta!",
                      "danger"
                    );
                  }
                );

                // setOptions('editar');
                // setOpenClose(!openClose);
                // setModalTitle('Editar sensor')
              }}
            >
              abrir
            </Button>
          </div>
        );
      },
    },
  ];

  const handleCreate = () => {
    const create = {
      name: name,
      local: local,
      mac: mac,
      branchId: branchId,
    };
    CreateSensor(
      create,
      () => {
        getData();
      },
      () => {
        getData();
      }
    );
  };
  const handleDigital = () => {
    CreateDigital(idOfList, macDigital, userId);
  };
  const handleEdit = () => {
    const edit: any = {
      name: name,
      local: local,
      mac: mac,
      branchId: branchId,
    };
    EditSensor(
      idSensor,
      edit,
      () => {
        getData();
      },
      () => {
        getData();
      }
    );
  };
  const handleEditUser = () => {
    let GruposSelecionados: any = [];
    group?.map((value: any) => {
      if (targetKeysGrupos.includes(value.id.toString())) {
        GruposSelecionados.push({
          id: value.id,
        });
      }
    });
    const edit: any = {
      group: {
        set: GruposSelecionados,
      },
    };

    EditSensor(
      idSensor,
      edit,
      () => {
        getData();
      },
      () => {
        getData();
      }
    );
  };
  const handleDelete = () => {
    DeleteSensor(
      idSensor,
      () => {
        getData();
      },
      () => {
        getData();
      }
    );
  };
  const data: DataType[] = sensor?.map((value: any, index: any) => {
    return {
      branchId: value.branchId,
      key: index + 1,
      name: value.name,
      id: value.id,
      local: value.local,
      usuarios: value.usuarios,
      group: value.group,
      mac: value.mac,
      digitalUsers: value.digitalUsers,
    };
  });
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          style={{ margin: "10px", fontSize: fontSizeResponsive }}
          onClick={() => {
            setOpenClose(!openClose);
            setModalTitle("Adicionar sensor");
            setOptions("adicionar");
          }}
        >
          Adicionar sensor
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data?.filter((value: any) => {
          return value.branchId == branchUser;
        })}
      />

      <ModalGeral
        title={modalTitle}
        confirm={() => {
          if (options == "adicionar") {
            return handleCreate(), setOpenClose(!openClose);
          }
          if (options == "digital") {
            return handleDigital(), setOpenClose(!openClose);
          }
          if (options == "editar") {
            return handleEdit(), setOpenClose(!openClose);
          }
          if (options == "group") {
            return handleEditUser(), setOpenClose(!openClose);
          }
          if (options == "deletar") {
            return handleDelete(), setOpenClose(!openClose);
          }
        }}
        cancel={() => {
          setOpenClose(!openClose);
          getData();
          if (options == "adicionar") {
            setName("");
            setMac("");
            setLocal("");
            setBranchId([]);
          }
        }}
        isModalOpen={openClose}
        children={
          options == "deletar" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60px",
              }}
            >
              <Button value="large">{name}</Button>
            </div>
          ) : (
            modalChildren[options]
          )
        }
      />
    </div>
  );
}
