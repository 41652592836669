import axios from "axios";
import { GROUP, USER } from "../../../constains";
import { Dispatch, SetStateAction } from "react";
import { CallNotification } from "../../../components/Alert";
const token = window.localStorage.getItem("accessToken");
const access = {
    headers: {
        Authorization: "Bearer " + token,
    },
};




export const CreateGroup= (
    user: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .post(`${GROUP}`, user, access)
        .then(response => {
            sucessCallback()
            CallNotification("Sucesso", "Grupo cadastrado com sucesso!", "success");
        })
        .catch(error => {
            failureCallback()
            CallNotification("Error", "Falha ao cadastrar grupo!", "danger");
        });

}
export const GetGroup = (
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${GROUP}`, access)
        .then(res => {
     
            setState(res.data.data);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const GetOneGroup = (
    id: string,
    setState: Dispatch<SetStateAction<any[]>>,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .get(`${GROUP}/${id}`, access)
        .then(res => {
            // console.log(res.data.branch)
    
            setState(res.data.branch);
            sucessCallback()
        })
        .catch(error => {
            failureCallback()
        });

}
export const EditGroup = (
    id: string,
    user: any,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .put(`${GROUP}/${id}`, user, access)
        .then(res => {
          
            CallNotification("Sucesso", "Grupo editado com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao editar grupo!", "danger");
            failureCallback()
        });

}
export const DeleteGroup = (
    id: string,
    sucessCallback: () => any = () => { },
    failureCallback: () => any = () => { }


) => {

    return axios
        .delete(`${GROUP}/${id}`, access)
        .then(res => {
     
            CallNotification("Sucesso", "Grupo deletado com sucesso!", "success");
            sucessCallback()
        })
        .catch(error => {
            CallNotification("Error", "Falha ao deletar grupo!", "danger");
            failureCallback()
        });

}

