import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Radio, Select, SelectProps } from 'antd';
import { AiOutlineIdcard, AiOutlineMail } from 'react-icons/ai';

import { GetBranches, GetOneUser } from './apiUser';


interface IAddUser {
    name: string,
    idUser: string,
    email: string,
    password: string,
    admin: boolean,
    branch: any,
    cardId: any,
    setName: Dispatch<SetStateAction<any>>,
    setEmail: Dispatch<SetStateAction<any>>,
    setPassword: Dispatch<SetStateAction<any>>,
    setAdmin: Dispatch<SetStateAction<any>>,
    setBranch: Dispatch<SetStateAction<any>>,
    setCardId: Dispatch<SetStateAction<any>>,

}


export default function EditarUsuario({
    name,
    email,
    password,
    admin,
    setName,
    setEmail,
    setPassword,
    setAdmin,
    idUser,
    setBranch,
    branch,
    setCardId,
    cardId,
}: IAddUser
) {

    const [branches, setBranches] = useState<any>(null)
    const [userEdit, setUserEdit] = useState<any>(null);

    const getData = () => {
        GetOneUser(idUser, setUserEdit)
    }

    useEffect(() => {
        getData()
        GetBranches(setBranches)
    }, [idUser])


    const defaultBranch = userEdit?.branches?.map((value: any) => { return value.id }) || []

    useEffect(() => {
        setName(userEdit?.name)
        setEmail(userEdit?.email)
        setAdmin(userEdit?.admin)
        setBranch(defaultBranch)
        setCardId(userEdit?.cardId)
    }, [userEdit, idUser])


    const options: SelectProps['options'] = branches?.map((value: any) => {
        return { value: value.id, label: value.name }
    });





    return (<div>



        <Input
            value={name}
            onChange={(ev: any) => { setName(ev.target.value) }}
            placeholder="Enter your username"
            prefix={<UserOutlined rev={1} className="site-form-item-icon" />}
        />
        <Input
            value={email}
            onChange={(ev: any) => { setEmail(ev.target.value) }}
            placeholder="Enter your email"
            prefix={<AiOutlineMail className="site-form-item-icon" />}
        />
        <Input
            value={cardId}
            onChange={(ev: any) => { setCardId(ev.target.value) }}
            placeholder="Enter your ID card"
            prefix={<AiOutlineIdcard className="site-form-item-icon" />}

        />

        {/* <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select the company"

            value={branch}
            onChange={(ev) => {

                setBranch(ev)
            }}
            options={options}
        /> */}



        <Radio onClick={() => { setAdmin(!admin) }} checked={admin}>Admin</Radio>



    </div>)
}