
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table, Tag } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';

import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { GetHistory } from './apiHistory';
import moment from 'moment';
import ModalGeral from '../../../components/Modal';
import CadastrarCartao from './CadastrarCartao';
import { EditUser } from '../UsuarioAdmin/apiUser';



export default function Historico() {
  interface DataType {
    key: string,
    name: string,
    access: boolean,
    user: any,
    local: any,
    createAt: any,
    cardId: any,
  }

  type DataIndex = keyof DataType;
  const [history, setHistory] = useState<any>(null);

  const [userId, setUserId] = useState<any>([]);
  const [cardId, setCardId] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState<any>(null);
  const [nameUser, setNameUser] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [openClose, setOpenClose] = useState<boolean>(false);
  const searchInput = useRef<InputRef>(null);

  const modalChildren: { [key: string]: JSX.Element } = {
    cartao: <CadastrarCartao
      setUserId={setUserId}
      userId={userId}
    />
  }

  const getData = () => {
    GetHistory(setHistory)
  }

  useEffect(() => {
    getData()
  }, [])


  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>

        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined rev={1} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined rev={2} style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    // Table.EXPAND_COLUMN,
    {
      title: 'Acesso',
      dataIndex: 'access',
      key: 'access',
      width: '0.01%',
      filters: [
        {
          text: 'Permitido',
          value: 'Permitido',
        },
        {
          text: 'Negado',
          value: 'Negado',
        },
      ],
      // @ts-ignore
      onFilter: (value: string, record) => record.access.indexOf(value) === 0,
      render: (_, record) => (
        // console.log(record)
        // console.log(_)
        <Space size="large">


          {/* @ts-ignore */}
          <Tag color={record.access == 'Permitido' ? 'green' : 'red'} >
            {record.access}
          </Tag>


        </Space>
      ),
    },

    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      ...getColumnSearchProps('name'),


    },


    {
      title: 'Local',
      dataIndex: 'local',
      key: 'local',
      width: '10%',
      ...getColumnSearchProps('local'),
      // render: (_, record) => (
      //   <>
      //     {/* @ts-ignore
      //               {record?.users?.map((value: any) => {
      //                   return value.name
      //               })} */}
      //     <AiOutlineEye
      //       style={{ cursor: 'pointer' }}

      //       onClick={() => {

      //         setOpenClose(!openClose)

      //       }} />
      //   </>
      // ),

    },
    // {
    //   title: 'Acesso',
    //   dataIndex: 'access',
    //   key: 'access',
    //   width: '10%',
    //   ...getColumnSearchProps('access'),
    //   // render: (_, record) => (
    //   //   // console.log(record)
    //   //   <>
    //   //     {/* @ts-ignore
    //   //               {record?.users?.map((value: any) => {
    //   //                   return value.name
    //   //               })} */}
    //   //     <AiOutlineEye
    //   //       style={{ cursor: 'pointer' }}

    //   //       onClick={() => {

    //   //         setOpenClose(!openClose)

    //   //       }} />
    //   //   </>
    //   // ),

    // },
    {
      title: 'Data e hora',
      dataIndex: 'createAt',
      key: 'createAt',
      width: '10%',
      ...getColumnSearchProps('createAt'),
      // render: (_, record) => (
      //   // console.log(record)
      //   <>
      //     {/* @ts-ignore
      //               {record?.users?.map((value: any) => {
      //                   return value.name
      //               })} */}
      //     <AiOutlineEye
      //       style={{ cursor: 'pointer' }}

      //       onClick={() => {

      //         setOpenClose(!openClose)

      //       }} />
      //   </>
      // ),

    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '3%',
      // ...getColumnSearchProps('action'),
      render: (_, record) => (
        // console.log(record)
        <Space size="large">
          <a onClick={() => {
            setModalTitle('Cadastrar cartão ao usuário')
            setOptions('cartao')
            setOpenClose(!openClose);
            setCardId(record.cardId)

          }}>
            {<AiOutlinePlusCircle />}</a>

        </Space>
      ),

    },
  ];


  const data: DataType[] = history?.map((value: any, index: any) => {


    return {
      key: index + 1,
      name: value?.user?.name == undefined ? 'Não cadastrado' : value?.user?.name,
      local: value?.sensor?.local,
      access: value?.access == true ? 'Permitido' : 'Negado',
      createAt: moment(value?.createAt).format('DD/MM/YYYY HH:mm'),
      cardId: value?.cardId
    }
  })


  const handleEdit = () => {
    const edit: any = {
      cardId: cardId,

    }

    EditUser(
      userId,
      edit,
      () => {
        getData();
      },
      () => {
        getData();
      }
    )
  }


  // console.log(history)

  return (

    <div>
      <Table columns={columns} dataSource={data}
      />

      <ModalGeral
        title={modalTitle}
        confirm={() => {
          if (options == 'cartao') { return handleEdit(), setOpenClose(!openClose) }

        }}
        cancel={() => {
          setOpenClose(!openClose)
        }}

        isModalOpen={openClose}

        children={options == 'deletar' ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>

            <Button value="large">{nameUser}</Button>

          </div>

          : modalChildren[options]}
      />


    </div>
  )
}