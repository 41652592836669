import { Dispatch, SetStateAction, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input } from 'antd';



interface IAddUser {
    name: string,
    setName: Dispatch<SetStateAction<any>>,

}


export default function AdicionarEmpresa({
    name,
    setName
}
    : IAddUser
) {



    useEffect(() => {
        setName('');
    }, [])


    return (<div>



        <Input
            value={name}
            onChange={(ev: any) => { setName(ev.target.value) }}
            placeholder="Enter your username"
            prefix={<UserOutlined rev={1} className="site-form-item-icon" />}

        />
    </div>)
}