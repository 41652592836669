import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Transfer } from 'antd';
import type { TransferDirection } from 'antd/es/transfer';
import { GetOneSensor } from './ApiSensor';
import { GetUser } from '../../Acesso/Usuario/apiUser';
import { GetGroup } from '../../Acesso/Grupos/apiGrupo';

interface RecordType {
    key: string;
    title: string;
}

interface IUsers {
    idSensor: string,
    setTargetKeysGrupos: Dispatch<SetStateAction<any[]>>,
    targetKeysGrupos: Array<string>
}

const Grupos = ({ idSensor, setTargetKeysGrupos, targetKeysGrupos }: IUsers) => {
    const [mockData, setMockData] = useState<RecordType[]>([]);
    const [sensorEditSelected, setSensorEditSelected] = useState<any>(null);
    const [grupo, setGrupo] = useState<any>(null);

// console.log(sensorEditSelected)
    const getMock = () => {
        const tempTargetKeys: any = [];
        // const tempMockData: any = [{key: '0', title: 'teste', description: 'tese', chose: false}];
        const tempMockData: any = grupo?.map((value: any, index: any) => {
            return { key: value.id.toString(), title: value.name }
        })


        sensorEditSelected?.group?.map((value: any, index: any) => {

            const data = {
                key: value.id.toString(),
                title: value.name,

            }
            if (data.key) {
                tempTargetKeys.push(data.key);

            }

        })
        setMockData(tempMockData);
        setTargetKeysGrupos(tempTargetKeys);
    };

    useEffect(() => {
        getMock();
    }, [grupo]);

    // const filterOption = (inputValue: string, option: RecordType) =>
    //     option.description.indexOf(inputValue) > -1;

    const handleChange = (newTargetKeys: string[]) => {
        // console.log(newTargetKeys)
        setTargetKeysGrupos(newTargetKeys);
    };

    const handleSearch = (dir: TransferDirection, value: string) => {
        console.log('search:', dir, value);
    };



    const getData = () => {
        GetOneSensor(idSensor, setSensorEditSelected)
        GetGroup(setGrupo)
    }

    useEffect(() => {
        getData()
    }, [idSensor])
  

    return (
        <div
            style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-around',
                width: '100%'
            }}>

                <h6>Disponíveis</h6>
                <h6>Selecionados</h6>

            </div>

            <Transfer
                dataSource={mockData}
                showSearch
                // filterOption={filterOption}
                targetKeys={targetKeysGrupos}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => {
                    return (item.title)
                }}
            />
        </div>
    );
};

export default Grupos;