import { Dispatch, SetStateAction, useEffect } from 'react';
import { Input } from 'antd';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import Usuarios from './Usuarios';
import Sensores from './Sensores';



interface IAddUser {
    GroupName: string,
    users: string,

    targetKeysSensors: string[],
    targetKeys: string[],

    sensors: string,
    setName: Dispatch<SetStateAction<any>>,
    setUsers: Dispatch<SetStateAction<any>>,
    setSensors: Dispatch<SetStateAction<any>>,

    setTargetKeysSensors: Dispatch<SetStateAction<any>>,
    setTargetKeys: Dispatch<SetStateAction<any>>,

    setGroupName: Dispatch<SetStateAction<any>>,

}


export default function AdicionarUsuario({
    GroupName,

    targetKeysSensors,
    targetKeys,

    setTargetKeysSensors,
    setTargetKeys,
    setGroupName
}
    : IAddUser
) {






    return (<div>



        <Input
            value={GroupName}
            onChange={(ev: any) => { setGroupName(ev.target.value) }}
            placeholder="Enter your group name"
            prefix={<AiOutlineUsergroupAdd className="site-form-item-icon" />}
        />

        <div style={{
            display: 'flex',
            margin: '10px 0px 0px 0px',
            flexDirection: 'column',

        }}>
            <h6>Usuários</h6>
            {/* @ts-ignore */}
            <Usuarios setTargetKeys={setTargetKeys} targetKeys={targetKeys} />


        </div>

        <div style={{
            display: 'flex',
            margin: '10px 0px 0px 0px',
            flexDirection: 'column',

        }}>
            <h6>Sensores</h6>
            {/* @ts-ignore */}
            <Sensores setTargetKeysSensors={setTargetKeysSensors} targetKeysSensors={targetKeysSensors} />


        </div>



    </div>)
}