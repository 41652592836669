import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Transfer } from 'antd';
import type { TransferDirection } from 'antd/es/transfer';
import { GetUser, getAllUsersByBranch } from '../Usuario/apiUser';
import { GetOneGroup } from './apiGrupo';
// import { GetOneSensor } from './ApiSensor';
// import { GetUser } from '../Acesso/Usuario/apiUser';

interface RecordType {
    key: string;
    title: string;

}


interface IUsers {
    setTargetKeys: Dispatch<SetStateAction<any[]>>,
    targetKeys: Array<string>,
    usersSelected: any,
}

const Usuarios = ({
    setTargetKeys,
    targetKeys,
    usersSelected,
}:
    IUsers
) => {
    const [mockData, setMockData] = useState<RecordType[]>([]);
    const [usuarios, setUsuarios] = useState<any>(null);
    const branchId = window.localStorage.getItem('branchId')

    // console.log(usuarios)

    const getMock = () => {
        const tempTargetKeys: any = [];
        // const tempMockData: any = [{key: '0', title: 'teste', description: 'tese', chose: false}];
        const tempMockData: any = usuarios?.filter((value: any) => {
            return value.admin !== true
        }).map((value: any, index: any) => {
            return { key: value.id.toString(), title: value.name }
        })


        usersSelected?.users?.filter((value: any) => {
            return value.admin !== true
        }).map((value: any, index: any) => {



            const data = {
                key: value.id.toString(),
                title: value.name,

            }
            if (data.key) {
                tempTargetKeys.push(data.key);

            }

        })
        setMockData(tempMockData);
        setTargetKeys(tempTargetKeys);
    };

    useEffect(() => {
        getMock();
    }, [usuarios, usersSelected]);

    // const filterOption = (inputValue: string, option: RecordType) =>
    //     option.description.indexOf(inputValue) > -1;

    const handleChange = (newTargetKeys: string[]) => {
        // console.log(newTargetKeys)
        setTargetKeys(newTargetKeys);
    };

    const handleSearch = (dir: TransferDirection, value: string) => {
        console.log('search:', dir, value);
    };



    const getData = () => {
        getAllUsersByBranch(branchId, setUsuarios)
    }



    useEffect(() => {
        getData()
    }, [])



    return (
        <div
            style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-around',
                width: '100%'
            }}>

                <h6>Disponíveis</h6>
                <h6>Selecionados</h6>

            </div>

            <Transfer
                dataSource={mockData}
                showSearch
                // filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => {
                    return (item.title)
                }}
            />
        </div>
    );
};

export default Usuarios;