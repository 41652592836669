import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Transfer } from 'antd';
import type { TransferDirection } from 'antd/es/transfer';

import { GetSensor } from '../../Portas/ApiSensor';
import { getAllSensorsByBranch } from '../Usuario/apiUser';
// import { GetOneSensor } from './ApiSensor';
// import { GetUser } from '../Acesso/Usuario/apiUser';

interface RecordType {
    key: string;
    title: string;
}

interface ISensors {
    setTargetKeysSensors: Dispatch<SetStateAction<any[]>>,
    targetKeysSensors: Array<string>,
    sensorsSelected: any,
}

const Sensores = ({
    setTargetKeysSensors,
    targetKeysSensors,
    sensorsSelected
}: ISensors
) => {
    const [mockData, setMockData] = useState<RecordType[]>([]);
    const [sensores, setSensores] = useState<any>(null);
    const branchId = window.localStorage.getItem('branchId')

    const getMock = () => {
        const tempTargetKeys: any = [];
        // const tempMockData: any = [{key: '0', title: 'teste', description: 'tese', chose: false}];
        const tempMockData: any = sensores?.map((value: any, index: any) => {
            return { key: value.id.toString(), title: value.name }
        })


        sensorsSelected?.sensors?.map((value: any, index: any) => {

            const data = {
                key: value.id.toString(),
                title: value.name,

            }
            if (data.key) {
                tempTargetKeys.push(data.key);

            }

        })
        setMockData(tempMockData);
        setTargetKeysSensors(tempTargetKeys);
    };

    useEffect(() => {
        getMock();
    }, [sensores, sensorsSelected]);

    // const filterOption = (inputValue: string, option: RecordType) =>
    //     option.description.indexOf(inputValue) > -1;

    const handleChange = (newTargetKeys: string[]) => {
        // console.log(newTargetKeys)
        setTargetKeysSensors(newTargetKeys);
    };

    const handleSearch = (dir: TransferDirection, value: string) => {
        console.log('search:', dir, value);
    };



    const getData = () => {
        GetSensor(setSensores)
        getAllSensorsByBranch(branchId, setSensores)
    }


   
    useEffect(() => {
        getData()
    }, [])

    

    return (
        <div
            style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-around',
                width: '100%'
            }}>

                <h6>Disponíveis</h6>
                <h6>Selecionados</h6>

            </div>

            <Transfer
                dataSource={mockData}
                showSearch
                // filterOption={filterOption}
                targetKeys={targetKeysSensors}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => {
                    return (item.title)
                }}
            />
        </div>
    );
};

export default Sensores;