import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Input, Select, SelectProps } from 'antd';
import { MdOutlinePlace, MdSensors } from 'react-icons/md';
import { VscVmActive } from 'react-icons/vsc';
import { GetBranches } from '../Admin/UsuarioAdmin/apiUser';



interface IAddUser {
    name: string,
    local: string,
    mac: string,
    branchId: string,
    setName: Dispatch<SetStateAction<any>>,
    setLocal: Dispatch<SetStateAction<any>>,
    setMac: Dispatch<SetStateAction<any>>,
    setBranchId: Dispatch<SetStateAction<any>>,
}


export default function AdicionarSensor({
    name,
    mac,
    local,
    branchId,
    setName,
    setMac,
    setLocal,
    setBranchId
}
    : IAddUser
) {
    const [branches, setBranches] = useState<any>(null)
    const branchUser = window.localStorage.getItem('branchId')


    useEffect(() => {
        setName('');
        setMac('');
        setLocal('');
        setBranchId([]);
    }, [])

    useEffect(() => {
        GetBranches(setBranches)
    }, [])



    const options: SelectProps['options'] = branches?.map((value: any) => {
        return { value: value.id, label: value.name }
    });




    return (<div>



        <Input
            value={name}
            onChange={(ev: any) => { setName(ev.target.value) }}
            placeholder="Enter your username"
            prefix={<MdSensors className="site-form-item-icon" />}

        />
        <Input
            value={local}
            onChange={(ev: any) => { setLocal(ev.target.value) }}
            placeholder="Enter your place"
            prefix={<MdOutlinePlace className="site-form-item-icon" />}

        />
        <Input
            value={mac}
            onChange={(ev: any) => { setMac(ev.target.value) }}
            placeholder="Enter your mac"
            prefix={<VscVmActive className="site-form-item-icon" />}

        />
        <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select the company"
            value={branchId}
            onChange={(ev) => {
                setBranchId(ev)
            }}
            options={options?.filter((value: any) => {
                // console.log(value)
                return value.value == branchUser
            })}
        />



    </div>)
}