import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Radio, Select } from 'antd';
import { AiOutlineIdcard, AiOutlineMail } from 'react-icons/ai';
import { BsKey } from 'react-icons/bs';
import { MdWorkOutline } from 'react-icons/md';

import type { SelectProps } from 'antd';
import { GetBranches } from './apiUser';





interface IAddUser {
    name: string,
    email: string,
    password: string,
    admin: boolean,
    branch: any,
    cardId: any,
    setUserAdm: any
}


export default function AdicionarUsuario({
    name,
    email,
    password,
    admin,
    branch,
    cardId,
    setUserAdm
}
    : IAddUser
) {

    const [branches, setBranches] = useState<any>(null)


    useEffect(() => {
        setUserAdm({ admin: false, email: '', nameUser: '', passwordUser: '', branch: [], cardId: null });
    }, [])


    useEffect(() => {
        GetBranches(setBranches)
    }, [name])




    const options: SelectProps['options'] = branches?.map((value: any) => {
        return { value: value.id, label: value.name }
    });










    return (<div>



        <Input
            value={name}
            onChange={(ev: any) => { setUserAdm({ nameUser: ev.target.value }) }}
            placeholder="Enter your username"
            prefix={<UserOutlined rev={1} className="site-form-item-icon" />}

        />
        <Input
            value={email}
            onChange={(ev: any) => { setUserAdm({ email: ev.target.value }) }}
            placeholder="Enter your email"
            prefix={<AiOutlineMail className="site-form-item-icon" />}

        />
        <Input
            value={cardId}
            onChange={(ev: any) => { setUserAdm({ cardId: ev.target.value }); }}
            placeholder="Enter your ID card"
            prefix={<AiOutlineIdcard className="site-form-item-icon" />}

        />
        <Input.Password
            value={password}
            onChange={(ev: any) => { setUserAdm({ passwordUser: ev.target.value }); }}
            placeholder="input password"
            prefix={<BsKey className="site-form-item-icon" />}
            iconRender={(visible) => (visible ? <EyeTwoTone rev={3} /> : <EyeInvisibleOutlined rev={4} />)}
        />
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select the company"
            value={branch}
            onChange={(ev) => {
                setUserAdm({ branch: ev });
            }}
            options={options}
        />


        <Radio onClick={() => {  setUserAdm({ admin: !admin })}} checked={admin}>Admin</Radio>



    </div>)
}