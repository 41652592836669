export const WindowHeigth = window.innerHeight;
export const WindowWidth = window.innerWidth;
export const fontSizeResponsive = WindowWidth > 500 ? '15px' : '7px'
export const iconResponsive = WindowWidth > 500 ? '20px' : '10px'






