import { Button } from 'antd';


export default function ViewUsers({ viewUsers }: any) {
    // console.log(viewUsers)

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            {viewUsers.length !== 0 ? viewUsers?.filter((value: any) => {
                return value.admin !== true
            }).map((value: any, index: any) => {
                return <Button key={index}>{value.name}</Button>
            }) : 'Este grupo não possui usuários'}
        </div>)

}