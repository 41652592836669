const host = window.location.hostname;
// const protocol = window.location.protocol;
export const URL_API = `http://${host}`
export const PORT_API = 3333
export const LIST_APS = `${URL_API}:${PORT_API}/admin/api/resources/AP/actions/list`


export const LOGIN_URL = `/api/login`;
export const ADMIN_URL = `/api/user`;
export const ADMIN_BRANCH_URL = `/api/branch`;
export const ADMIN_COMPANY_URL = `/api/company`;
export const SENSOR_URL = `/api/sensor`;
export const SENSOR_DATA_URL = `/api/sensor/data`;
export const SENSOR_DATA_GROUP_URL = `/api/sensorData/group`;



export const USER = `/api/user`
export const SENSOR = `/api/sensor`
export const DELETE_DIGITAL = `/api/mqtt/deleteFingerprint`
export const GROUP = `/api/group`
export const EMPRESA = `/api/branch`
export const HISTORY = `/api/history`
export const OPENDOOR = `/api/mqtt/open?mac=`
export const ACCESS = `/api/access`
export const ENROLL = `/api/mqtt/enroll`
export const LIST_MAC = `/api/mqtt/connected`
// export const LIST_MAC = `http://acesso.redezone.com.br:5001/mqtt/connected`