import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Radio } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';

import { GetOneEmpresa } from './apiEmpresa';


interface IAddUser {
    name: string,
    setName: Dispatch<SetStateAction<any>>,
    empresaId: string
}


export default function EditarUsuario({
    name,
    setName,
    empresaId
}
    : IAddUser
) {
    const [empresa, setEmpresa] = useState<any>(null)


    const getData = () => {
        GetOneEmpresa(empresaId, setEmpresa)
    }

    useEffect(() => {
        getData()
    }, [empresaId])

    useEffect(() => {
        setName(empresa?.name)
    }, [empresa])



    return (<div>

        <Input
            value={name}
            onChange={(ev: any) => { setName(ev.target.value) }}
            placeholder="Enter your username"
            prefix={<UserOutlined rev={1} className="site-form-item-icon" />}

        />

    </div>)
}