import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Input, Radio, Select } from 'antd';
import type { SelectProps } from 'antd';
import { GetUser } from '../Admin/UsuarioAdmin/apiUser';
import { getAllUsersByBranch } from '../Acesso/Usuario/apiUser';






interface IAddCard {
  userId: any,
  setUserId: Dispatch<SetStateAction<any>>,

}


export default function CadastrarDigital({
  userId,
  setUserId,


}
  : IAddCard
) {

  const [users, serUsers] = useState<any>(null)
  const branchUser = window.localStorage.getItem('branchId')



  useEffect(() => {
    getAllUsersByBranch(branchUser, serUsers)
  }, [])









  const options: SelectProps['options'] = users?.filter(((value: any) => {
    return value.admin !== true
  })).map((value: any) => {

    return { value: value.id, label: value.name }

  });




  return (<div>


    <Select
      // mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Please select the user"
      value={userId}
      onChange={(ev) => {

        setUserId(ev)
      }}
      options={options}
    />



  </div>)
}