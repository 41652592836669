import React, { useState } from "react";
import "./App.css";
import "./styles/App.scss";
import "./styles/graphTheme/google-charts.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import styled, { ThemeProvider } from "styled-components";
import dark from "./styles/themes/dark";
import light from "./styles/themes/light";
import GlobalStyles from "./styles/GlobalStyles";
import { Colors } from "./styles/cssVariables";
import Router from "./router/index";
import { ReactNotifications } from "react-notifications-component";
import { ConfigProvider, theme, Space } from "antd";
import { HappyProvider } from "@ant-design/happy-work-theme";
import { WindowWidth, fontSizeResponsive } from "./components/WidthXHeigth";

function App() {
  const select: string = window.localStorage.getItem("theme") || "light";
  let selectTheme;
  let antdTheme;
  if (select == "dark") {
    selectTheme = dark;
    antdTheme = theme.darkAlgorithm;
  } else {
    selectTheme = light;
    antdTheme = theme.darkAlgorithm;
  }

  const [tema, setTema] = useState(selectTheme);




 

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          // Seed Token
          colorPrimary: "#64df2b",
          // borderRadius: 20,

          // Alias Token
          // colorBgContainer: '#0a0a09',
        },
      }}
    >
      <HappyProvider>
        <ThemeProvider theme={tema}>
          <Colors>
            <ReactNotifications />
            <GlobalStyles />
            <Router setTheme={setTema} />
          </Colors>
        </ThemeProvider>
      </HappyProvider>
    </ConfigProvider>
  );
}

export default App;
